import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link, useNavigate } from "react-router-dom";
import "./Header.css"; // Import the CSS file
import Dropdown from 'react-bootstrap/Dropdown';

const Header = () => {
  const navigate = useNavigate();
  const [showSidebar, setShowSidebar] = useState(false);
  const token = localStorage.getItem("token");
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [selectedApplication, setSelectedApplication] = useState("");

  const setApplicationFromLocalStorage = () => {
    const application = localStorage.getItem("application") || "Tatami";
    setSelectedApplication(application);
  };

  useEffect(() => {
    setApplicationFromLocalStorage();
  }, []);

  const handleTatamiClick = () => {
    localStorage.setItem("application", "Tatami");
    setApplicationFromLocalStorage(); // Update selected application after setting
  };

  const handleQuickchopClick = () => {
    localStorage.setItem("application", "QuickChop");
    setApplicationFromLocalStorage(); // Update selected application after setting
  };

  const handleLogout = () => {
    // Remove the token from local storage
    localStorage.removeItem("token");
    localStorage.removeItem("application");
    // Navigate to the login component
    navigate("/");
    // Close the sidebar
    setShowSidebar(false);
  };

  const handleLinkClick = () => {
    setShowSidebar(false);
  };

  // let deferredPrompt = null
  // /** handle pwa popup */
  // window.addEventListener("beforeinstallprompt", (e) => {
  //   try {
  //     e.preventDefault();
  //     deferredPrompt = e;
  //   } catch (error) {
  //     console.log(error)
  //   }
  // });

  // const installApp = () => {
  //   console.log("calling...")
  //   if (deferredPrompt) {
  //     console.log("in if...")
  //     deferredPrompt.prompt();
  //     deferredPrompt.userChoice.then((choiceResult) => {
  //       if (choiceResult.outcome === 'accepted') {
  //         localStorage.setItem("installpreference", "installed")
  //       } else {
  //         localStorage.setItem("installpreference", "false")
  //       }
  //     });
  //   }
  // }

  useEffect(() => {
    const beforeInstallPromptHandler = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };

    window.addEventListener("beforeinstallprompt", beforeInstallPromptHandler);

    return () => {
      window.removeEventListener("beforeinstallprompt", beforeInstallPromptHandler);
    };
  }, []);

  const handlePWAEvent = () => {
    try {
      if (deferredPrompt) {
        deferredPrompt.prompt();
        deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            localStorage.setItem("installpreference", "installed");
          } else {
            localStorage.setItem("installpreference", "false");
          }
          setDeferredPrompt(null);
        });
      }
      else {
        console.log("deferredPrompt is null. PWA installation prompt not available.");
      }
    } catch (error) {
      console.log(error, 'error');
    }
  }

  const installApp = () => {
    try {
      handlePWAEvent();
    } catch (error) {
      console.log(error, 'error');
    }
  }

  return (
    <>
      <Navbar className="navbar-style" variant="dark">
        <Container>
          <Navbar.Brand onClick={() => setShowSidebar(true)}>
            <span className="navbar-toggler-icon"></span>
          </Navbar.Brand>
          <div className="d-flex align-items-center ml-auto">
            {token && (
              <>
                <button className="btn btn-primary" onClick={installApp}>Install App</button>
              </>
            )}
            {!token && (
              <>
                <Dropdown data-bs-theme="dark" style={{ marginLeft: "5px" }}>
                  <Dropdown.Toggle id="dropdown-button-dark-example1" variant="secondary">
                    {selectedApplication}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={handleTatamiClick}>Tatami</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={handleQuickchopClick}>QuickChop</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </>
            )}
          </div>
        </Container>
      </Navbar>

      <Offcanvas
        show={showSidebar}
        onHide={() => setShowSidebar(false)}
        className="offcanvas-style" // Apply the Offcanvas styles
      >
        <Offcanvas.Header closeButton>
          {/* <Offcanvas.Title>Tatami Console</Offcanvas.Title> */}
          <Offcanvas.Title>
            {selectedApplication === "Tatami" && "Tatami Console"}
            {selectedApplication === "QuickChop" && "QuickChop Console"}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="offcanvas-body-style">
          <Nav className="flex-column">
            {!token && (
              <>
                <Nav.Link
                  as={Link}
                  to="/"
                  className="nav-link"
                  onClick={handleLinkClick}
                >
                  Login
                </Nav.Link>
              </>
            )}
          </Nav>
          <Nav className="flex-column">
            {token && (
              <>
                <Nav.Link
                  as={Link}
                  to="/dashboard"
                  className="nav-link"
                  onClick={handleLinkClick}
                >
                  Dashboard
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/transactions"
                  className="nav-link"
                  onClick={handleLinkClick}
                >
                  Transactions
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/firebase"
                  className="nav-link"
                  onClick={handleLinkClick}
                >
                  Push Notification
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/userbets"
                  className="nav-link"
                  onClick={handleLinkClick}
                >
                  Userbets
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/users"
                  className="nav-link"
                  onClick={handleLinkClick}
                >
                  Users
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/send-xaf"
                  className="nav-link"
                  onClick={handleLinkClick}
                >
                  Send XAF
                </Nav.Link>
                <Nav.Link
                  className="nav-link"
                  onClick={handleLogout}
                >
                  Logout
                </Nav.Link>
              </>
            )}
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Header;
