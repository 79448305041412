import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Pagination from "react-bootstrap/Pagination";
import Loader from "../Loader/Loader";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { endpoint } from "../../../utils/endpoint";
import { fetchPost } from "../../../utils/network";

const Userbets = () => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [showLoader, setshowLoader] = useState(false);
    const [loading, setLoading] = useState(false);
    const [totalAllUserbetsPages, setAllTotalUserbetsPages] = useState(1);
    const [currentAllUserbetPage, setAllCurrentUserbetPage] = useState(1);
    const [totalSearchedUserbetsPages, setSearchedTotalUserbetsPages] = useState(1);
    const [currentSearchedUserbetPage, setSearchedCurrentUserbetPage] = useState(1);
    const [showAllBets, setShowAllBets] = useState(true);
    const [allUserbets, setAllUserbets] = useState([]);
    const [searchedUserbets, setSearchedUserbets] = useState([]);
    const pageSize = 10;
    const pagesToShow = 5;
    const navigate = useNavigate();

    const refreshPage = () => {
        window.location.reload();
    }

    const fetchSearchedUserbets = useCallback(async () => {
        setLoading(true);
        try {
            setshowLoader(true);
            let obj = {
                pageNumber: currentSearchedUserbetPage,
                pageSize: pageSize,
                phoneNumber: `+237${phoneNumber}`
            }
            const response = await fetchPost(endpoint["search-userbets"], obj, navigate);
            setshowLoader(false);
            if (response.responseData && response.responseData.userBets) {
                setSearchedUserbets(response.responseData.userBets);
                setSearchedTotalUserbetsPages(response.responseData.totalPages);
            }
        } catch (error) {
            throw error;
        } finally {
            setLoading(false);
            setshowLoader(false);
        }
    }, [currentSearchedUserbetPage, pageSize, phoneNumber, navigate]);

    const searchUserbet = async (e) => {
        e.preventDefault();
        setLoading(true);
        setShowAllBets(false);
        try {
            await fetchSearchedUserbets();
        } catch (error) {
            console.error("Error while searching:", error.message);
        } finally {
            setLoading(false);
            setshowLoader(false);
        }
    };

    useEffect(() => {
        if (!showAllBets) {
            fetchSearchedUserbets();
        }
    }, [showAllBets, fetchSearchedUserbets]);

    useEffect(() => {
        const fetchAllUserbets = async () => {
            try {
                setshowLoader(true);
                let obj = {
                    pageNumber: currentAllUserbetPage,
                    pageSize: pageSize,
                }
                const response = await fetchPost(endpoint["get-all-userbets"], obj, navigate);
                setshowLoader(false);
                if (response.responseData && response.responseData.userBets) {
                    setAllUserbets(response.responseData.userBets);
                    setAllTotalUserbetsPages(response.responseData.totalPages);
                }
                else {
                    console.error("Invalid response format");
                }
            } catch (error) {
                throw error;
            } finally {
                setLoading(false);
                setshowLoader(false);
            }
        };
        fetchAllUserbets();
    }, [currentAllUserbetPage, navigate]);

    const handleAllUserbetsPageChange = (page) => {
        setAllCurrentUserbetPage(page);
    };

    const renderAllUserbetsPaginationItems = () => {
        const items = [];
        const startPage = Math.max(1, currentAllUserbetPage - Math.floor(pagesToShow / 2));
        const endPage = Math.min(totalAllUserbetsPages, startPage + pagesToShow - 1);

        items.push(
            <Pagination.First key="first" onClick={() => handleAllUserbetsPageChange(1)} disabled={currentAllUserbetPage === 1} />
        );
        items.push(
            <Pagination.Prev
                key="prev"
                onClick={() => handleAllUserbetsPageChange(currentAllUserbetPage - 1)}
                disabled={currentAllUserbetPage === 1}
            />
        );

        for (let i = startPage; i <= endPage; i++) {
            items.push(
                <Pagination.Item
                    key={i}
                    active={i === currentAllUserbetPage}
                    onClick={() => handleAllUserbetsPageChange(i)}
                >
                    {i}
                </Pagination.Item>
            );
        }

        items.push(
            <Pagination.Next
                key="next"
                onClick={() => handleAllUserbetsPageChange(currentAllUserbetPage + 1)}
                disabled={currentAllUserbetPage === totalAllUserbetsPages}
            />
        );
        items.push(
            <Pagination.Last
                key="last"
                onClick={() => handleAllUserbetsPageChange(totalAllUserbetsPages)}
                disabled={currentAllUserbetPage === totalAllUserbetsPages}
            />
        );

        return items;
    };

    const handleSearchedUserbetsPageChange = (page) => {
        setSearchedCurrentUserbetPage(page);
    };

    const renderSearchedUserbetsPaginationItems = () => {
        const items = [];
        const startPage = Math.max(1, currentSearchedUserbetPage - Math.floor(pagesToShow / 2));
        const endPage = Math.min(totalSearchedUserbetsPages, startPage + pagesToShow - 1);

        items.push(
            <Pagination.First key="first" onClick={() => handleSearchedUserbetsPageChange(1)} disabled={currentSearchedUserbetPage === 1} />
        );
        items.push(
            <Pagination.Prev
                key="prev"
                onClick={() => handleSearchedUserbetsPageChange(currentSearchedUserbetPage - 1)}
                disabled={currentSearchedUserbetPage === 1}
            />
        );

        for (let i = startPage; i <= endPage; i++) {
            items.push(
                <Pagination.Item
                    key={i}
                    active={i === currentSearchedUserbetPage}
                    onClick={() => handleSearchedUserbetsPageChange(i)}
                >
                    {i}
                </Pagination.Item>
            );
        }

        items.push(
            <Pagination.Next
                key="next"
                onClick={() => handleSearchedUserbetsPageChange(currentSearchedUserbetPage + 1)}
                disabled={currentSearchedUserbetPage === totalSearchedUserbetsPages}
            />
        );
        items.push(
            <Pagination.Last
                key="last"
                onClick={() => handleSearchedUserbetsPageChange(totalSearchedUserbetsPages)}
                disabled={currentSearchedUserbetPage === totalSearchedUserbetsPages}
            />
        );

        return items;
    };

    function formatTimestamp(inputDate) {
        const date = new Date(inputDate);

        // Format date
        const formattedDate = `${date.getFullYear()}/${padNumber(date.getMonth() + 1)}/${padNumber(date.getDate())}`;

        // Format time
        const hours = padNumber(date.getHours());
        const minutes = padNumber(date.getMinutes());
        // const seconds = padNumber(date.getSeconds());
        const meridiem = hours >= 12 ? 'PM' : 'AM';
        const formattedTime = `${hours % 12 || 12}:${minutes} ${meridiem}`;

        // Combine date and time
        const formattedDateTime = `${formattedDate}, ${formattedTime}`;

        return formattedDateTime;
    }

    function padNumber(number) {
        return number < 10 ? `0${number}` : number;
    }


    return (
        <>
            {showLoader && <Loader></Loader>}
            <div>
                <Container className="mt-3">
                    <Row>
                        <Col>
                            <h1 className="text-center">Userbets</h1>
                            <Container className="text-center">
                                <Row className="justify-content-center">
                                    <Col xs={12} sm={8} md={6} lg={4} className="my-3">
                                        <InputGroup>
                                            <InputGroup.Text>+237</InputGroup.Text>
                                            <Form.Control
                                                placeholder="Phone number"
                                                value={phoneNumber}
                                                onChange={(e) => setPhoneNumber(e.target.value)}
                                            />
                                            <Button
                                                variant="primary"
                                                onClick={searchUserbet}
                                                disabled={loading}
                                            >
                                                {loading ? 'Searching...' : 'Search'}
                                            </Button>
                                            <Button variant="outline-dark" onClick={refreshPage}>Clear</Button>
                                        </InputGroup>
                                    </Col>
                                </Row>
                            </Container>

                            <Table striped bordered hover responsive>
                                <thead>
                                    <tr>
                                        <th>Username</th>
                                        <th>Phone</th>
                                        <th>Amount</th>
                                        <th>Balance Before</th>
                                        <th>Balance After </th>
                                        <th>Winning Balance After</th>
                                        <th>Balance Result</th>
                                        <th>Profit</th>
                                        <th>Result</th>
                                        <th>Status</th>
                                        <th>Bet Place Dat</th>
                                        <th>Bet Start Time</th>
                                        <th>Bet End Time</th>
                                        <th>Bet Duration</th>
                                        <th>Bet Type</th>
                                        <th>Cashed Out</th>
                                        <th>Cashout Event</th>
                                        <th>Round Busted</th>
                                        <th>User ID</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {showAllBets ? (
                                        allUserbets.map((userbet) => (
                                            <tr key={userbet._id}>
                                                <td>{userbet.username}</td>
                                                <td>{userbet.phoneNo}</td>
                                                <td>{userbet.amount}</td>
                                                <td>
                                                    {typeof userbet.balanceBefore === 'number'
                                                        ? userbet.balanceBefore.toFixed(2)  // Convert to fixed decimal if it's a number
                                                        : !isNaN(parseFloat(userbet.balanceBefore))
                                                            ? parseFloat(userbet.balanceBefore).toFixed(2)  // If it's a string representation of a number
                                                            : userbet.balanceBefore  // Otherwise, display the original value
                                                    }
                                                </td>
                                                <td>
                                                    {typeof userbet.balanceAfter === 'number'
                                                        ? userbet.balanceAfter.toFixed(2)  // Convert to fixed decimal if it's a number
                                                        : !isNaN(parseFloat(userbet.balanceAfter))
                                                            ? parseFloat(userbet.balanceAfter).toFixed(2)  // If it's a string representation of a number
                                                            : userbet.balanceAfter  // Otherwise, display the original value
                                                    }
                                                </td>
                                                <td>{userbet.winningBalanceAfter}</td>
                                                <td>{userbet.balanceResult}</td>
                                                <td>
                                                    {typeof userbet.profit === 'number'
                                                        ? userbet.profit.toFixed(2)  // Convert to fixed decimal if it's a number
                                                        : !isNaN(parseFloat(userbet.profit))
                                                            ? parseFloat(userbet.profit).toFixed(2)  // If it's a string representation of a number
                                                            : userbet.profit  // Otherwise, display the original value
                                                    }
                                                </td>
                                                <td>{userbet.result}</td>
                                                <td>{userbet.status}</td>
                                                <td>{formatTimestamp(userbet.betplacedat)}</td>
                                                <td>{formatTimestamp(userbet.betStartTime)}</td>
                                                <td>{formatTimestamp(userbet.betEndTime)}</td>
                                                <td>{userbet.betDuration}</td>
                                                <td>{userbet.betType}</td>
                                                <td>{userbet.cashedOut}</td>
                                                <td>{userbet.cashoutEvent}</td>
                                                <td>{userbet.roundBusted}</td>
                                                <td>{userbet.userId}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        searchedUserbets.map((userbet) => (
                                            <tr key={userbet._id}>
                                                <td>{userbet.username}</td>
                                                <td>{userbet.phoneNo}</td>
                                                <td>{userbet.amount}</td>
                                                <td>
                                                    {typeof userbet.balanceBefore === 'number'
                                                        ? userbet.balanceBefore.toFixed(2)  // Convert to fixed decimal if it's a number
                                                        : !isNaN(parseFloat(userbet.balanceBefore))
                                                            ? parseFloat(userbet.balanceBefore).toFixed(2)  // If it's a string representation of a number
                                                            : userbet.balanceBefore  // Otherwise, display the original value
                                                    }
                                                </td>
                                                <td>
                                                    {typeof userbet.balanceAfter === 'number'
                                                        ? userbet.balanceAfter.toFixed(2)  // Convert to fixed decimal if it's a number
                                                        : !isNaN(parseFloat(userbet.balanceAfter))
                                                            ? parseFloat(userbet.balanceAfter).toFixed(2)  // If it's a string representation of a number
                                                            : userbet.balanceAfter  // Otherwise, display the original value
                                                    }
                                                </td>
                                                <td>{userbet.winningBalanceAfter}</td>
                                                <td>{userbet.balanceResult}</td>
                                                <td>
                                                    {typeof userbet.profit === 'number'
                                                        ? userbet.profit.toFixed(2)  // Convert to fixed decimal if it's a number
                                                        : !isNaN(parseFloat(userbet.profit))
                                                            ? parseFloat(userbet.profit).toFixed(2)  // If it's a string representation of a number
                                                            : userbet.profit  // Otherwise, display the original value
                                                    }
                                                </td>
                                                <td>{userbet.result}</td>
                                                <td>{userbet.status}</td>
                                                <td>{formatTimestamp(userbet.betplacedat)}</td>
                                                <td>{formatTimestamp(userbet.betStartTime)}</td>
                                                <td>{formatTimestamp(userbet.betEndTime)}</td>
                                                <td>{userbet.betDuration}</td>
                                                <td>{userbet.betType}</td>
                                                <td>{userbet.cashedOut}</td>
                                                <td>{userbet.cashoutEvent}</td>
                                                <td>{userbet.roundBusted}</td>
                                                <td>{userbet.userId}</td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                            </Table>
                            <Row>
                                <Col className="d-flex justify-content-end">
                                    <Pagination>
                                        {showAllBets
                                            ? renderAllUserbetsPaginationItems()
                                            : renderSearchedUserbetsPaginationItems()}
                                    </Pagination>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container >
            </div >
        </>
    );
}

export default Userbets;
