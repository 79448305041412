import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Pagination from "react-bootstrap/Pagination";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Button from "react-bootstrap/Button";
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import Loader from "../Loader/Loader";
import { endpoint } from "../../../utils/endpoint";
import { fetchPost } from "../../../utils/network";

const Transactions = () => {
    const [showLoader, setshowLoader] = useState(false);
    const [transactions, setTransactions] = useState([]);
    const [pendingTransactions, setPendingTransactions] = useState([]);
    const [approvedTransactions, setApprovedTransactions] = useState([]);
    const [currentTransactionsPage, setCurrentTransactionsPage] = useState(1);
    const [currentPendingTransactionsPage, setCurrentPendingTransactionsPage] = useState(1);
    const [currentApprovedTransactionsPage, setCurrentApprovedTransactionsPage] = useState(1);
    const [totalTransactionsPages, setTotalTransactionsPages] = useState(1);
    const [totalPendingTransactionsPages, setTotalPendingTransactionsPages] = useState(1);
    const [totalApprovedTransactionsPages, setTotalApprovedTransactionsPages] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const pageSize = 10;
    const pagesToShow = 5;
    const navigate = useNavigate();

    const handleViewClick = (transaction) => {
        setSelectedTransaction(transaction);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedTransaction(null);
    };

    useEffect(() => {
        const fetchTransactions = async () => {
            try {
                setshowLoader(true);
                let obj = {
                    pageNumber: currentTransactionsPage,
                    pageSize: pageSize,
                }
                const response = await fetchPost(endpoint["get-all-transactions"], obj, navigate);
                // console.log("All Transactions", response);
                setshowLoader(false);
                if (response.responseData && response.responseData.transactions) {
                    setTransactions(response.responseData.transactions);
                    setTotalTransactionsPages(response.responseData.totalPages);
                }
            } catch (error) {
                throw error;
            }
        };
        fetchTransactions();
    }, [currentTransactionsPage, navigate]);

    useEffect(() => {
        const fetchPendingTransactions = async () => {
            try {
                setshowLoader(true);
                let obj = {
                    pageNumber: currentPendingTransactionsPage,
                    pageSize: pageSize,
                }
                const response = await fetchPost(endpoint["get-pending-transactions"], obj, navigate);
                // console.log("pending Transactions", response);
                setshowLoader(false);
                if (response.responseData && response.responseData.pendingTransactions) {
                    setPendingTransactions(response.responseData.pendingTransactions);
                    setTotalPendingTransactionsPages(response.responseData.totalPages);
                }
            } catch (error) {
                throw error;
            }
        };
        fetchPendingTransactions();
    }, [currentPendingTransactionsPage, navigate]);

    useEffect(() => {
        const fetchApprovedTransactions = async () => {
            try {
                setshowLoader(true);
                let obj = {
                    pageNumber: currentApprovedTransactionsPage,
                    pageSize: pageSize,
                }
                const response = await fetchPost(endpoint["get-approved-transactions"], obj, navigate);
                // console.log("approved Transactions", response);
                setshowLoader(false);
                if (response.responseData && response.responseData.approvedTransactions) {
                    setApprovedTransactions(response.responseData.approvedTransactions);
                    setTotalApprovedTransactionsPages(response.responseData.totalPages);
                }
            } catch (error) {
                throw error;
            }
        };
        fetchApprovedTransactions();
    }, [currentApprovedTransactionsPage, navigate]);

    const approvePendingTransaction = async (transactionId) => {
        try {
            setshowLoader(true);
            let obj = {
                _id: transactionId,
            }
            const response = await fetchPost(endpoint["approve-pending-transaction"], obj, navigate);
            // console.log(response);
            setshowLoader(false);
            if (response.responseCode === 200) {
                toast.success(`Transaction with ID ${transactionId} has been approved.`);
            } else {
                toast.error(`Error approving transaction: ${response.responseData}`);
            }
        } catch (error) {
            toast.error(`Error approving transaction: ${error.message}`);
        }
    };

    const declinePendingTransaction = async (transactionId) => {
        try {
            setshowLoader(true);
            let obj = {
                _id: transactionId,
            }
            const response = await fetchPost(endpoint["decline-pending-transaction"], obj, navigate);
            // console.log(response);
            setshowLoader(false);
            if (response.responseCode === 200) {
                toast.success(`Transaction with ID ${transactionId} has been declined.`);
            } else {
                toast.error(`Error declining transaction: ${response.responseData}`);
            }
        } catch (error) {
            toast.error(`Error declining transaction: ${error.message}`);
        }
    };

    const formatTimestamp = (timestamp) => {
        const options = { year: "numeric", month: "numeric", day: "numeric", hour: "numeric", minute: "numeric" };
        return new Intl.DateTimeFormat("en-US", options).format(new Date(timestamp));
    };

    const handleTransactionsPageChange = (page) => {
        setCurrentTransactionsPage(page);
    };

    const handlePendingTransactionsPageChange = (page) => {
        setCurrentPendingTransactionsPage(page);
    };

    const handleApprovedTransactionsPageChange = (page) => {
        setCurrentApprovedTransactionsPage(page);
    };

    const renderTransactionsPaginationItems = () => {
        const items = [];
        const startPage = Math.max(1, currentTransactionsPage - Math.floor(pagesToShow / 2));
        const endPage = Math.min(totalTransactionsPages, startPage + pagesToShow - 1);

        items.push(
            <Pagination.First key="first" onClick={() => handleTransactionsPageChange(1)} disabled={currentTransactionsPage === 1} />
        );
        items.push(
            <Pagination.Prev
                key="prev"
                onClick={() => handleTransactionsPageChange(currentTransactionsPage - 1)}
                disabled={currentTransactionsPage === 1}
            />
        );

        for (let i = startPage; i <= endPage; i++) {
            items.push(
                <Pagination.Item
                    key={i}
                    active={i === currentTransactionsPage}
                    onClick={() => handleTransactionsPageChange(i)}
                >
                    {i}
                </Pagination.Item>
            );
        }

        items.push(
            <Pagination.Next
                key="next"
                onClick={() => handleTransactionsPageChange(currentTransactionsPage + 1)}
                disabled={currentTransactionsPage === totalTransactionsPages}
            />
        );
        items.push(
            <Pagination.Last
                key="last"
                onClick={() => handleTransactionsPageChange(totalTransactionsPages)}
                disabled={currentTransactionsPage === totalTransactionsPages}
            />
        );

        return items;
    };

    const renderPendingTransactionsPaginationItems = () => {
        const items = [];
        const startPage = Math.max(1, currentPendingTransactionsPage - Math.floor(pagesToShow / 2));
        const endPage = Math.min(totalPendingTransactionsPages, startPage + pagesToShow - 1);

        items.push(
            <Pagination.First key="first" onClick={() => handlePendingTransactionsPageChange(1)} disabled={currentPendingTransactionsPage === 1} />
        );
        items.push(
            <Pagination.Prev
                key="prev"
                onClick={() => handlePendingTransactionsPageChange(currentPendingTransactionsPage - 1)}
                disabled={currentPendingTransactionsPage === 1}
            />
        );

        for (let i = startPage; i <= endPage; i++) {
            items.push(
                <Pagination.Item
                    key={i}
                    active={i === currentPendingTransactionsPage}
                    onClick={() => handlePendingTransactionsPageChange(i)}
                >
                    {i}
                </Pagination.Item>
            );
        }

        items.push(
            <Pagination.Next
                key="next"
                onClick={() => handlePendingTransactionsPageChange(currentPendingTransactionsPage + 1)}
                disabled={currentPendingTransactionsPage === totalPendingTransactionsPages}
            />
        );
        items.push(
            <Pagination.Last
                key="last"
                onClick={() => handlePendingTransactionsPageChange(totalPendingTransactionsPages)}
                disabled={currentPendingTransactionsPage === totalPendingTransactionsPages}
            />
        );

        return items;
    };

    const renderApprovedTransactionsPaginationItems = () => {
        const items = [];
        const startPage = Math.max(1, currentApprovedTransactionsPage - Math.floor(pagesToShow / 2));
        const endPage = Math.min(totalApprovedTransactionsPages, startPage + pagesToShow - 1);

        items.push(
            <Pagination.First key="first" onClick={() => handleApprovedTransactionsPageChange(1)} disabled={currentApprovedTransactionsPage === 1} />
        );
        items.push(
            <Pagination.Prev
                key="prev"
                onClick={() => handleApprovedTransactionsPageChange(currentApprovedTransactionsPage - 1)}
                disabled={currentApprovedTransactionsPage === 1}
            />
        );

        for (let i = startPage; i <= endPage; i++) {
            items.push(
                <Pagination.Item
                    key={i}
                    active={i === currentApprovedTransactionsPage}
                    onClick={() => handleApprovedTransactionsPageChange(i)}
                >
                    {i}
                </Pagination.Item>
            );
        }

        items.push(
            <Pagination.Next
                key="next"
                onClick={() => handleApprovedTransactionsPageChange(currentApprovedTransactionsPage + 1)}
                disabled={currentApprovedTransactionsPage === totalApprovedTransactionsPages}
            />
        );
        items.push(
            <Pagination.Last
                key="last"
                onClick={() => handleApprovedTransactionsPageChange(totalApprovedTransactionsPages)}
                disabled={currentApprovedTransactionsPage === totalApprovedTransactionsPages}
            />
        );

        return items;
    };

    return (
        <>
            {showLoader && <Loader></Loader>}
            <div>
                <Container className="mt-5">
                    <Row>
                        <Col>
                            <h1 className="text-center">Transactions</h1>
                            <Tabs defaultActiveKey="transactions" id="transactions-tabs">
                                <Tab eventKey="transactions" title={<span style={{ color: 'black' }}>All Transactions</span>}>
                                    <Table striped bordered hover responsive>
                                        <thead>
                                            <tr>
                                                <th>Username</th>
                                                <th>Phone</th>
                                                <th>Amount</th>
                                                <th>Country</th>
                                                <th>Timestamp</th>
                                                <th>Payment Type</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {transactions.map((transaction) => (
                                                <tr key={transaction._id}>
                                                    <td>{transaction.user?.userName ?? 'N/A'}</td>
                                                    <td>{transaction.user?.phoneNumber ?? 'N/A'}</td>
                                                    <td>{transaction.amount}</td>
                                                    <td>{transaction.country}</td>
                                                    <td>{formatTimestamp(transaction.customerTimestamp)}</td>
                                                    <td>{transaction.paymentType}</td>
                                                    <td>{transaction.status}</td>
                                                    <td><Button variant="outline-primary" onClick={() => handleViewClick(transaction)}>View</Button></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                    <Modal show={showModal} onHide={handleCloseModal}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Transaction Details</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            {selectedTransaction && (
                                                <div>
                                                    <p>Transaction ID: <strong>{selectedTransaction._id}</strong></p>
                                                    <p>Username: <strong>{selectedTransaction.user?.userName ?? 'N/A'}</strong></p>
                                                    <p>Phone: <strong>{selectedTransaction.user?.phoneNumber ?? 'N/A'}</strong></p>
                                                    <p>Amount: <strong>{selectedTransaction.amount}</strong></p>
                                                    <p>Country: <strong>{selectedTransaction.country}</strong></p>
                                                    <p>Currency: <strong>{selectedTransaction.currency}</strong></p>
                                                    <p>Timestamp: <strong>{formatTimestamp(selectedTransaction.customerTimestamp)}</strong></p>
                                                    <p>Correspondent: <strong>{selectedTransaction.correspondent}</strong></p>
                                                    <p>Payer Address: <strong>{selectedTransaction.payerAddress}</strong></p>
                                                    <p>Payer Type: <strong>{selectedTransaction.payerType}</strong></p>
                                                    <p>Payment Type: <strong>{selectedTransaction.paymentType}</strong></p>
                                                    <p>Statement Description: <strong>{selectedTransaction.statementDescription}</strong></p>
                                                    <p>Status: <strong>{selectedTransaction.status}</strong></p>
                                                </div>
                                            )}
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={handleCloseModal}>
                                                Close
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                    <Row>
                                        <Col className="d-flex justify-content-end">
                                            <Pagination>{renderTransactionsPaginationItems()}</Pagination>
                                        </Col>
                                    </Row>
                                </Tab>
                                <Tab eventKey="pendingTransactions" title={<span style={{ color: 'black' }}>Pending Transactions</span>}>
                                    {pendingTransactions.length === 0 ? (
                                        <p>No pending transactions</p>
                                    ) : (
                                        <div>
                                            <Table striped bordered hover responsive>
                                                <thead>
                                                    <tr>
                                                        <th>Username</th>
                                                        <th>Phone</th>
                                                        <th>Amount</th>
                                                        <th>Country</th>
                                                        <th>Timestamp</th>
                                                        <th>Payment Type</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {pendingTransactions.map((transaction) => (
                                                        <tr key={transaction._id}>
                                                            <td>{transaction.user.userName}</td>
                                                            <td>{transaction.user.phoneNumber}</td>
                                                            <td>{transaction.amount}</td>
                                                            <td>{transaction.country}</td>
                                                            <td>{formatTimestamp(transaction.customerTimestamp)}</td>
                                                            <td>{transaction.paymentType}</td>
                                                            <td>{transaction.status}</td>
                                                            <td>
                                                                <Button
                                                                    variant="success"
                                                                    onClick={() => approvePendingTransaction(transaction._id)}
                                                                >
                                                                    Approve
                                                                </Button>
                                                                {" "}
                                                                <Button
                                                                    variant="outline-danger"
                                                                    onClick={() => declinePendingTransaction(transaction._id)}
                                                                >Decline</Button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                            <Row>
                                                <Col className="d-flex justify-content-end">
                                                    <Pagination>{renderPendingTransactionsPaginationItems()}</Pagination>
                                                </Col>
                                            </Row>
                                        </div>
                                    )}
                                </Tab>
                                <Tab eventKey="approvedTransactions" title={<span style={{ color: 'black' }}>Approved Transactions</span>}>
                                    {approvedTransactions.length === 0 ? (
                                        <p>No approved transactions</p>
                                    ) : (
                                        <div>
                                            <Table striped bordered hover responsive>
                                                <thead>
                                                    <tr>
                                                        <th>Username</th>
                                                        <th>Phone</th>
                                                        <th>Amount</th>
                                                        <th>Country</th>
                                                        <th>Timestamp</th>
                                                        <th>Payment Type</th>
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {approvedTransactions.map((transaction) => (
                                                        <tr key={transaction._id}>
                                                            <td>{transaction.user.userName}</td>
                                                            <td>{transaction.user.phoneNumber}</td>
                                                            <td>{transaction.amount}</td>
                                                            <td>{transaction.country}</td>
                                                            <td>{formatTimestamp(transaction.customerTimestamp)}</td>
                                                            <td>{transaction.paymentType}</td>
                                                            <td>{transaction.status}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                            <Row>
                                                <Col className="d-flex justify-content-end">
                                                    <Pagination>{renderApprovedTransactionsPaginationItems()}</Pagination>
                                                </Col>
                                            </Row>
                                        </div>
                                    )}
                                </Tab>
                            </Tabs>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default Transactions;