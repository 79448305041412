import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from "../Loader/Loader";
import { endpoint } from "../../../utils/endpoint";
import { fetchPost } from "../../../utils/network";
import {
    Card, CardBody, CardHeader, Col,
    Container, Row, Button,
    Form, Pagination,
    InputGroup, CardText,
    Modal
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Users = () => {
    const navigate = useNavigate();
    const [showLoader, setshowLoader] = useState(false);
    const [loading, setLoading] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState([]);
    const [users, setUsers] = useState([]);
    const [searchUser, setSearchUser] = useState([]);
    const [totalAllUserPages, setAllTotalUserPages] = useState(1);
    const [currentAllUserPage, setAllCurrentUserPage] = useState(1);
    const [showAllUsers, setShowAllUsers] = useState(true);
    const [showPagination, setShowPagination] = useState(true);
    // const [showModal, setShowModal] = useState(false);
    // const [selectedUser, setSelectedUser] = useState(null);
    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
    const [showChangeBalanceModal, setShowChangeBalanceModal] = useState(false);
    const [password, setPassword] = useState('');
    const [balance, setBalance] = useState('');
    const [selectedUserPhoneNumber, setSelectedUserPhoneNumber] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const pageSize = 9;
    const pagesToShow = 5;

    const handleBlockUser = async (phoneNumber) => {
        setshowLoader(true)
        try {
            let obj = {
                phoneNumber: phoneNumber
            }
            const response = await fetchPost(endpoint["block-user"], obj, navigate);
            setshowLoader(false);
            if (response.responseCode === 200) {
                toast.success("User blocked successfully");
                fetchSearchedUser();
                fetchAllUser();
            } else {
                toast.error("Getting error while blocking user");
            }
        } catch (error) {
            console.error("Error while blocking user:", error.message);
        } finally {
            setshowLoader(false);
        }
    }

    const handleUnblockUser = async (phoneNumber) => {
        setshowLoader(true)
        try {
            let obj = {
                phoneNumber: phoneNumber
            }
            const response = await fetchPost(endpoint["unblock-user"], obj, navigate);
            setshowLoader(false);
            if (response.responseCode === 200) {
                toast.success("User unblocked successfully");
                fetchSearchedUser();
                fetchAllUser();
            } else {
                toast.error("Getting error while unblocking user");
            }
        } catch (error) {
            console.error("Error while blocking user:", error.message);
        } finally {
            setshowLoader(false);
        }
    }

    const refreshPage = () => {
        window.location.reload();
    }

    const handleViewClick = (user_id) => {
        navigate(`/user/${user_id}/view`);
        // setSelectedUser(transaction);
        // setShowModal(true);
    };

    // const handleCloseModal = () => {
    //     setShowModal(false);
    //     setSelectedUser(null);
    // };

    const handleShowChangePasswordModal = (phoneNumber) => {
        setSelectedUserPhoneNumber(phoneNumber);
        setShowChangePasswordModal(true);
    };

    const handleCloseChangePasswordModal = () => {
        setShowChangePasswordModal(false);
    }

    const handleChangePassword = async () => {
        handleCloseChangePasswordModal();
        setshowLoader(true);
        try {
            let obj = {
                phoneNumber: selectedUserPhoneNumber,
                password: password
            }
            const response = await fetchPost(endpoint["change-user-password"], obj, navigate);
            setshowLoader(false);
            if (response.responseCode === 200) {
                toast.success("Password changed successfully");
                setPassword('');
            } else {
                toast.error("Getting error while changing password");
            }
        } catch (error) {
            throw error;
        } finally {
            setshowLoader(false);
        }
    }

    const handleShowChangeBalanceModal = (phoneNumber) => {
        setSelectedUserPhoneNumber(phoneNumber);
        setShowChangeBalanceModal(true);
    };

    const handleCloseChangeBalanceModal = () => {
        setShowChangeBalanceModal(false);
    }

    const handleChangeBalance = async () => {
        handleCloseChangeBalanceModal();
        setshowLoader(true);
        try {
            let obj = {
                phoneNumber: selectedUserPhoneNumber,
                balance: balance
            }
            const response = await fetchPost(endpoint["change-balance-of-the-user"], obj, navigate);
            setshowLoader(false);
            if (response.responseCode === 200) {
                toast.success("Balance changed successfully");
                setBalance('');
                refreshPage();
            } else {
                toast.error("Getting error while changing balance");
            }
        } catch (error) {
            throw error;
        } finally {
            setshowLoader(false);
        }
    }

    const fetchSearchedUser = useCallback(async () => {
        setLoading(true);
        try {
            setshowLoader(true);
            let obj = {
                phoneNumber: `+237${phoneNumber}`
            }
            const response = await fetchPost(endpoint["search-user-by-phone-number"], obj, navigate);
            setshowLoader(false);
            if (response.responseData && response.responseData.user) {
                setSearchUser(response.responseData.user);
            }
        } catch (error) {
            throw error;
        } finally {
            setLoading(false);
            setshowLoader(false);
        }
    }, [phoneNumber, navigate]);

    const searchUserByPhoneNumber = async (e) => {
        e.preventDefault();
        setLoading(true);
        setShowAllUsers(false);
        setShowPagination(false);
        try {
            await fetchSearchedUser();
        } catch (error) {
            console.error("Error while searching:", error.message);
        } finally {
            setLoading(false);
            setshowLoader(false);
        }
    };

    useEffect(() => {
        if (!showAllUsers) {
            fetchSearchedUser();
        }
    }, [showAllUsers, fetchSearchedUser]);

    // useEffect(() => {
    //     const fetchAllUser = async () => {
    //         try {
    //             setshowLoader(true);
    //             let obj = {
    //                 pageNumber: currentAllUserPage,
    //                 pageSize: pageSize,
    //             }
    //             const response = await fetchPost(endpoint["get-all-users-details"], obj, navigate);
    //             setshowLoader(false);
    //             if (response.responseData && response.responseData.users) {
    //                 setUsers(response.responseData.users);
    //                 setAllTotalUserPages(response.responseData.totalPages);
    //             }
    //         } catch (error) {
    //             throw error;
    //         } finally {
    //             setLoading(false);
    //             setshowLoader(false);
    //         }
    //     };
    //     fetchAllUser();
    // }, [currentAllUserPage, navigate]);

    const fetchAllUser = useCallback(async () => {
        try {
            setshowLoader(true);
            let obj = {
                pageNumber: currentAllUserPage,
                pageSize: pageSize,
            };
            const response = await fetchPost(endpoint["get-all-users-details"], obj, navigate);
            setshowLoader(false);
            if (response.responseData && response.responseData.users) {
                setUsers(response.responseData.users);
                setAllTotalUserPages(response.responseData.totalPages);
            }
        } catch (error) {
            throw error;
        } finally {
            setLoading(false);
            setshowLoader(false);
        }
    }, [currentAllUserPage, navigate]);

    useEffect(() => {
        fetchAllUser();
    }, [fetchAllUser]);


    const handleAllUserPageChange = (page) => {
        setAllCurrentUserPage(page);
    };

    const renderAllUserPaginationItems = () => {
        const items = [];
        const startPage = Math.max(1, currentAllUserPage - Math.floor(pagesToShow / 2));
        const endPage = Math.min(totalAllUserPages, startPage + pagesToShow - 1);

        items.push(
            <Pagination.First key="first" onClick={() => handleAllUserPageChange(1)} disabled={currentAllUserPage === 1} />
        );
        items.push(
            <Pagination.Prev
                key="prev"
                onClick={() => handleAllUserPageChange(currentAllUserPage - 1)}
                disabled={currentAllUserPage === 1}
            />
        );

        for (let i = startPage; i <= endPage; i++) {
            items.push(
                <Pagination.Item
                    key={i}
                    active={i === currentAllUserPage}
                    onClick={() => handleAllUserPageChange(i)}
                >
                    {i}
                </Pagination.Item>
            );
        }

        items.push(
            <Pagination.Next
                key="next"
                onClick={() => handleAllUserPageChange(currentAllUserPage + 1)}
                disabled={currentAllUserPage === totalAllUserPages}
            />
        );
        items.push(
            <Pagination.Last
                key="last"
                onClick={() => handleAllUserPageChange(totalAllUserPages)}
                disabled={currentAllUserPage === totalAllUserPages}
            />
        );

        return items;
    };

    function formatTimestamp(inputDate) {
        const date = new Date(inputDate);

        // Format date
        const formattedDate = `${date.getFullYear()}/${padNumber(date.getMonth() + 1)}/${padNumber(date.getDate())}`;

        // Format time
        const hours = padNumber(date.getHours());
        const minutes = padNumber(date.getMinutes());
        // const seconds = padNumber(date.getSeconds());
        const meridiem = hours >= 12 ? 'PM' : 'AM';
        const formattedTime = `${hours % 12 || 12}:${minutes} ${meridiem}`;

        // Combine date and time
        const formattedDateTime = `${formattedDate}, ${formattedTime}`;

        return formattedDateTime;
    }

    function padNumber(number) {
        return number < 10 ? `0${number}` : number;
    }

    return (
        <>
            {showLoader && <Loader></Loader>}
            <Container className="text-center">
                <h1 className="text-center mt-5">Users</h1>
                <Row className="justify-content-center">
                    <Col xs={12} sm={8} md={6} lg={4} className="my-3">
                        <InputGroup>
                            <InputGroup.Text>+237</InputGroup.Text>
                            <Form.Control
                                placeholder="Phone number"
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                            />
                            <Button
                                variant="primary"
                                onClick={searchUserByPhoneNumber}
                                disabled={loading}
                            >
                                {loading ? 'Searching...' : 'Search'}
                            </Button>
                            <Button variant="outline-dark" onClick={refreshPage}>Clear</Button>
                        </InputGroup>
                    </Col>
                </Row>
                {showAllUsers && (
                    users.reduce((rows, user, index) => {
                        if (index % 3 === 0) rows.push([]);
                        rows[rows.length - 1].push(
                            <Col xs={12} sm={6} md={4} lg={4} key={index}>
                                <Card className="mt-2">
                                    <CardHeader>Username: <strong>{user.userName}</strong></CardHeader>
                                    <CardBody>
                                        <CardText>Phone Number: <strong>{user.phoneNumber}</strong></CardText>
                                        <CardText>Balance: <strong>{user.balance}</strong></CardText>
                                        <CardText>Joined at: <strong>{formatTimestamp(user.created)}</strong></CardText>
                                        <CardText>
                                            <span>
                                                {user.isAccountRestrictedByAdmin ? (
                                                    <Form.Check
                                                        type="switch"
                                                        id="custom-switch"
                                                        checked={user.isAccountRestrictedByAdmin === true}
                                                        onChange={() => handleUnblockUser(user.phoneNumber)}
                                                        label="Block User"
                                                        className="block-user-checkbox"
                                                        style={{ display: "inline-block", marginRight: "10px" }}
                                                    />
                                                ) : (
                                                    <Form.Check
                                                        type="switch"
                                                        id="custom-checkbox"
                                                        checked={false} // Change this to the appropriate value if needed
                                                        onChange={() => handleBlockUser(user.phoneNumber)}
                                                        label="Block User"
                                                        className="block-user-checkbox"
                                                        style={{ display: "inline-block", marginRight: "10px" }}
                                                    />
                                                )}
                                            </span>
                                        </CardText>
                                        <div className="d-flex justify-content-between">
                                            <Button variant="outline-dark" onClick={() => handleShowChangePasswordModal(user.phoneNumber)}>Change Password</Button>
                                            <Button variant="outline-primary" onClick={() => handleShowChangeBalanceModal(user.phoneNumber)}>Change Balance</Button>
                                            <Button variant="outline-success" onClick={() => handleViewClick(user._id)}>View Details</Button>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        );
                        return rows;
                    }, []).map((row, rowIndex) => (
                        <Row className="mb-3" key={rowIndex}>
                            {row}
                        </Row>
                    ))
                )}
                {!showAllUsers && searchUser && Object.keys(searchUser).length > 0 && (
                    <Row className="justify-content-center">
                        <Col className="col-12 col-md-6">
                            <Card className="mt-2 text-center">
                                <CardHeader>Searched User Details</CardHeader>
                                <CardBody className="text-left">
                                    <CardText>Username: <strong>{searchUser.userName}</strong></CardText>
                                    <CardText>Phone Number: <strong>{searchUser.phoneNumber}</strong></CardText>
                                    <CardText>Balance: <strong>{searchUser.balance}</strong></CardText>
                                    <CardText>Joined at: <strong>{formatTimestamp(searchUser.created)}</strong></CardText>
                                    <CardText>
                                        <span>
                                            {searchUser.isAccountRestrictedByAdmin ? (
                                                <Form.Check
                                                    type="switch"
                                                    id="custom-switch"
                                                    checked={searchUser.isAccountRestrictedByAdmin === true}
                                                    onChange={() => handleUnblockUser(searchUser.phoneNumber)}
                                                    label="Block User"
                                                    className="block-user-checkbox"
                                                    style={{ display: "inline-block", marginRight: "10px" }}
                                                />
                                            ) : (
                                                <Form.Check
                                                    type="switch"
                                                    id="custom-checkbox"
                                                    checked={false}
                                                    onChange={() => handleBlockUser(searchUser.phoneNumber)}
                                                    label="Block User"
                                                    className="block-user-checkbox"
                                                    style={{ display: "inline-block", marginRight: "10px" }}
                                                />
                                            )}
                                        </span>
                                    </CardText>
                                    <div className="d-flex justify-content-between">
                                        <Button variant="outline-dark" onClick={() => handleShowChangePasswordModal(searchUser.phoneNumber)}> Change Password</Button>
                                        <Button variant="outline-primary" onClick={() => handleShowChangeBalanceModal(searchUser.phoneNumber)}>Change Balance</Button>
                                        <Button variant="outline-success" onClick={() => handleViewClick(searchUser._id)}>View Details</Button>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                )}
                {!showAllUsers && (!searchUser || Object.keys(searchUser).length === 0) && (
                    <Row className="justify-content-center">
                        <Col className="col-12 col-md-6">
                            <Card className="mt-2 text-center">
                                <CardHeader>User Not Found</CardHeader>
                                <CardBody>
                                    <p>The user with the provided phone number was not found.</p>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                )}
                {showPagination && (
                    <Row>
                        <Col className="d-flex justify-content-end">
                            <Pagination>
                                {renderAllUserPaginationItems()}
                            </Pagination>
                        </Col>
                    </Row>
                )}
                {/* <Modal show={showModal} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>User Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {selectedUser && (
                            <div>
                                <p>ID: <strong>{selectedUser._id}</strong></p>
                                <p>Username: <strong>{selectedUser.userName ?? 'N/A'}</strong></p>
                                <p>Phone Number: <strong>{selectedUser.phoneNumber ?? 'N/A'}</strong></p>
                                <p>Balance: <strong>{selectedUser.balance}</strong></p>
                                <p>Bet Amount: <strong>{selectedUser.bet_amount}</strong></p>
                                <p>bonus: <strong>{selectedUser.bonus}</strong></p>
                                <p>Joined at: <strong>{formatTimestamp(selectedUser?.created) ?? 'N/A'}</strong></p>
                                <p>bonusAvailed: <strong>{selectedUser.bonusAvailed ? 'Yes' : 'No'}</strong></p>
                                {selectedUser.otp &&
                                    <p>OTP: <strong>{selectedUser?.otp ?? 'N/A'}</strong></p>
                                }
                                {selectedUser.otpExpiry &&
                                    <p>OTP Expiry: <strong>{formatTimestamp(selectedUser.otpExpiry)}</strong></p>
                                }
                                <p>payout_multiplier: <strong>{selectedUser.payout_multiplier}</strong></p>
                                <p>Profit: <strong>{selectedUser.profit}</strong></p>
                                <p>Referral Bonus: <strong>{selectedUser.referralBonus}</strong></p>
                                <p>Referral Number: <strong>{selectedUser.referralNo}</strong></p>
                                <p>Total Deposit: <strong>{selectedUser.totalDeposit}</strong></p>
                                <p>Total Profit: <strong>{selectedUser.totalProfit}</strong></p>
                                <p>Total WithDraw: <strong>{selectedUser.totalWithDraw}</strong></p>
                                {selectedUser.referral && selectedUser.referral.length > 0 ? (
                                    <div>
                                        <h3 className='text-center'>Referrals</h3>
                                        {selectedUser.referral.map((referral, index) => (
                                            <div key={index}>
                                                <p>ID: <strong>{referral._id}</strong></p>
                                                <p>Phone Number: <strong>{referral.phoneNumber}</strong></p>
                                                <p>Username: <strong>{referral.userName}</strong></p>
                                                <p>Joined At: <strong>{formatTimestamp(referral.createdAt)}</strong></p>
                                                <hr />
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <p>No referrals found for this user.</p>
                                )}
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal> */}
                <Modal show={showChangePasswordModal} onHide={handleCloseChangePasswordModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Change Password</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Enter password</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        type={showPassword ? "text" : "password"}
                                        placeholder="E.g. John@123"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        autoFocus
                                    />
                                    <Button variant="outline-secondary" onClick={() => setShowPassword(!showPassword)}>
                                        <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                                    </Button>
                                </InputGroup>
                            </Form.Group>

                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseChangePasswordModal}>
                            Close
                        </Button>
                        <Button
                            variant="primary"
                            onClick={handleChangePassword}
                            disabled={!password}
                        >
                            Change
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={showChangeBalanceModal} onHide={handleCloseChangeBalanceModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Change Balance</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Enter Balance</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        type="text"
                                        placeholder=""
                                        value={balance}
                                        onChange={(e) => setBalance(e.target.value)}
                                        autoFocus
                                    />
                                </InputGroup>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseChangeBalanceModal}>
                            Close
                        </Button>
                        <Button
                            variant="primary"
                            onClick={handleChangeBalance}
                            disabled={!balance}
                        >
                            Change
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container >
        </>
    )
}

export default Users;