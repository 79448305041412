// const baseURL = "http://localhost:3000";

const getBaseURL = () => {
    const application = localStorage.getItem("application");

    let baseURL;
    if (application === "Tatami") {
        baseURL = "https://api.tatami.cm";
    } else if (application === "QuickChop") {
        baseURL = "https://api.quickchop.cm";
    } else {
        baseURL = "https://api.tatami.cm";
    }

    return baseURL;
};

const fetchPost = async (url, data, navigate) => {
    try {
        const token = localStorage.getItem("token")
        const baseURL = getBaseURL();
        const response = await fetch(baseURL + url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        if (response.ok) {
            const result = await response.json()
            if (result.responseCode === 6032 || result.responseCode === 6028) {
                localStorage.removeItem('token')
                navigate("/");
            }
            return result
        } else {
            return {
                responseCode: 500,
                responseData: "Unknown error"
            }
        }
    } catch (error) {
        return {
            responseCode: 500,
            responseData: "Network error"
        }
    }
}


const fetchGet = async (url, navigate) => {
    try {
        const token = localStorage.getItem("token")
        const baseURL = getBaseURL();
        const response = await fetch(baseURL + url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        });
        if (response.ok) {
            const result = await response.json()
            if (result.responseCode === 6032 || result.responseCode === 6028) {
                localStorage.removeItem('token');
                navigate("/");
            }
            return result
        } else {
            return {
                responseCode: 500,
                responseData: "Unknown error"
            }
        }
    } catch (error) {
        return {
            responseCode: 500,
            responseData: "Network error"
        }
    }
}

// const fetchPostFirebasePushNotification = async (url, data, navigate) => {
//     try {
//         console.log(data);
//         const token = localStorage.getItem("token")
//         const response = await fetch(VITE_BE_BASE_URL + url, {
//             method: 'POST',
//             headers: {
//                 'Authorization': `Bearer ${token}`
//             },
//             body: data,
//         });
//         if (response.ok) {
//             const result = await response.json()
//             if (result.responseCode === 6032 || result.responseCode === 6028) {
//                 localStorage.removeItem('token')
//                 navigate("/");
//             }
//             return result
//         } else {
//             return {
//                 responseCode: 500,
//                 responseData: "Unknown error"
//             }
//         }
//     } catch (error) {
//         return {
//             responseCode: 500,
//             responseData: "Network error"
//         }
//     }
// }


export {
    fetchPost,
    fetchGet,
    // fetchPostFirebasePushNotification
}