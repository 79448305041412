import React, { useState, useEffect, useCallback } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row, Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";
import { endpoint } from "../../../utils/endpoint";
import { fetchGet, fetchPost } from "../../../utils/network";
import { Line, Bar } from "react-chartjs-2";
import Chart from 'chart.js/auto';
import { CategoryScale } from 'chart.js';
import "./Dashboard.css";

Chart.register(CategoryScale);


const Dashboard = () => {
    const navigate = useNavigate();
    const [showLoader, setshowLoader] = useState(false);
    const [dailyBetsChartData, setDailyBetsChartData] = useState([]);
    const [dailyBetsAmountChartData, setDailyBetsAmountChartData] = useState([]);
    const [usersChartData, setDailyUsersChartData] = useState([]);
    const [last28DaysHouseProfitChartData, setLast28DaysHouseProfitChartData] = useState([]);
    const [last28DaysHouseLossChartData, setLast28DaysHouseLossChartData] = useState([]);
    const [remainingDetails, setRemainingDetails] = useState([]);
    // const [selectedFilter, setSelectedFilter] = useState("1 Day");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    // const formatDate = (date) => date?.toLocaleDateString('en-US', {
    //     month: 'numeric', day: 'numeric', year: 'numeric'
    // });

    const fetchData = useCallback(async (startDate, endDate) => {
        try {
            setshowLoader(true);
            // const obj = {
            //     startDate: formatDate(startDate),
            //     endDate: formatDate(endDate),
            // };
            // const adjustedStartDate = new Date(startDate);
            // adjustedStartDate.setHours(adjustedStartDate.getHours() + 5);
            // const adjustedEndDate = new Date(endDate);
            // adjustedEndDate.setHours(23, 59, 59, 999);
            // const formattedStartDate = adjustedStartDate.toISOString();
            // const formattedEndDate = new Date(adjustedEndDate.getTime() + 5 * 60 * 60 * 1000).toISOString();
            // const obj = {
            //     startDate: formattedStartDate,
            //     endDate: formattedEndDate
            // };

            const adjustedStartDate = new Date(startDate.getTime() + (6 * 60 * 60 * 1000));
            adjustedStartDate.setUTCHours(0, 0, 0, 0);
            const adjustedEndDate = new Date(endDate.getTime() + (6 * 60 * 60 * 1000));
            adjustedEndDate.setUTCHours(23, 59, 59, 999);

            const obj = {
                startDate: adjustedStartDate,
                endDate: adjustedEndDate
            };

            const response = await fetchPost(endpoint["get-analytics-details"], obj, navigate);
            if (response) {
                setRemainingDetails(response.responseData);
            }
        } catch (error) {
            console.log(error)
        } finally {
            setshowLoader(false);
        }
    }, [navigate]);

    const handleSearch = useCallback(async () => {
        // console.log('sending request')
        await fetchData(startDate, endDate);
    }, [fetchData, startDate, endDate]);

    useEffect(() => {
        const getCurrentDate = () => {
            const currentDate = new Date();
            const startOfDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 1);
            const endOfDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
            setStartDate(startOfDay)
            setEndDate(endOfDay)
            fetchData(startOfDay, endOfDay);
        };
        getCurrentDate();
    }, [fetchData]);

    useEffect(() => {
        const getData = async () => {
            try {
                setshowLoader(true);
                const response1 = await fetchGet(
                    endpoint["get-analytics-chart-details"],
                    navigate
                );
                if (response1) {
                    // console.log(response1);
                    setDailyBetsChartData(response1.responseData.last28DaysUserbets);
                    setDailyUsersChartData(response1.responseData.last28DaysUserCounts);
                    setDailyBetsAmountChartData(response1.responseData.last28DaysPlacedUserbetsAmount);
                    setLast28DaysHouseProfitChartData(response1.responseData.last28DaysHouseProfit);
                    setLast28DaysHouseLossChartData(response1.responseData.last28DaysHouseLoss);
                }
                setshowLoader(false);
            } catch (error) {
                setshowLoader(false);
            }
        };

        getData();
    }, [navigate]);

    // const handleFilterChange = useCallback(async (filter) => {
    //     try {
    //         setshowLoader(true);
    //         let response;
    //         if (filter === "7 Days") {
    //             response = await fetchGet(endpoint["seven-days-analytics-details"], navigate);
    //         } else if (filter === "30 Days") {
    //             response = await fetchGet(endpoint["thirty-days-analytics-details"], navigate);
    //         } else {
    //             response = await fetchGet(endpoint["one-day-analytics-details"], navigate);
    //         }

    //         if (response) {
    //             setRemainingDetails(response.responseData);
    //         }
    //         setshowLoader(false);
    //     } catch (error) {
    //         setshowLoader(false);
    //     }
    // }, [navigate]);

    // useEffect(() => {
    //     handleFilterChange(selectedFilter);
    // }, [selectedFilter, handleFilterChange]);

    const dailyBetsChartLabels = dailyBetsChartData.map((data) => data.date);
    const dailyBetsChartValues = dailyBetsChartData.map((data) => data.count);

    const dailyUsersChartLabels = usersChartData.map((data) => data.date);
    const dailyUsersChartValues = usersChartData.map((data) => data.count);

    const dailyBetsAmountChartLabels = dailyBetsAmountChartData.map((data) => data.date);
    const dailyBetsAmountChartValues = dailyBetsAmountChartData.map((data) => data.totalAmount);

    const dailyHouseProfitChartLabels = last28DaysHouseProfitChartData.map((data) => data.date);
    const dailyHouseProfitChartValues = last28DaysHouseProfitChartData.map((data) => data.totalAmount.$numberDecimal);

    const dailyHouseLossChartValues = last28DaysHouseLossChartData.map((data) => data.totalAmount.$numberDecimal);

    const commonChartOptionsForCount = {
        scales: {
            x: {
                type: 'category',
                title: {
                    display: true,
                    text: 'Date',
                    color: 'black',
                    font: {
                        weight: 'bold'
                    }
                }
            },
            y: {
                title: {
                    display: true,
                    text: 'Count',
                    color: 'black',
                    font: {
                        weight: 'bold'
                    }
                }
            }
        },
    };

    const commonChartOptionsForAmount = {
        scales: {
            x: {
                type: 'category',
                title: {
                    display: true,
                    text: 'Date',
                    color: 'black',
                    font: {
                        weight: 'bold'
                    }
                }
            },
            y: {
                title: {
                    display: true,
                    text: 'Amount',
                    color: 'black',
                    font: {
                        weight: 'bold'
                    }
                }
            }
        },
    };

    return (
        <>
            {showLoader && <Loader></Loader>}
            <h1 className="text-center mt-5">Analytics</h1>
            {/* <div className="d-flex align-items-center justify-content-center">
                <Form.Check
                    inline
                    type="radio"
                    label="1 Day"
                    name="filterRadio"
                    id="1DayRadio"
                    checked={selectedFilter === "1 Day"}
                    onChange={() => setSelectedFilter("1 Day")}
                />
                <Form.Check
                    inline
                    type="radio"
                    label="7 Days"
                    name="filterRadio"
                    id="7DaysRadio"
                    checked={selectedFilter === "7 Days"}
                    onChange={() => setSelectedFilter("7 Days")}
                />
                <Form.Check
                    inline
                    type="radio"
                    label="30 Days"
                    name="filterRadio"
                    id="30DaysRadio"
                    checked={selectedFilter === "30 Days"}
                    onChange={() => setSelectedFilter("30 Days")}
                />
            </div> */}

            <Container>
                <div className="row mt-5">
                    <Col xs={4} sm={4} md={4} lg={4}>
                        <label className="m-1">Start Date:</label>
                        <DatePicker
                            selected={startDate ? startDate : new Date()}
                            onChange={(date) => setStartDate(date)}
                            selectsStart
                            startDate={startDate}
                            dateFormat="MM/dd/yyyy"
                            className="form-control"
                            placeholderText="Start Date"
                        />

                    </Col>
                    <Col xs={4} sm={4} md={4} lg={4}>
                        <label className="m-1">End Date:</label>

                        <DatePicker
                            selected={endDate ? endDate : new Date()}
                            onChange={(date) => setEndDate(date)}
                            selectsEnd
                            endDate={endDate}
                            dateFormat="MM/dd/yyyy"
                            className="form-control"
                            placeholderText="End Date"
                        />
                    </Col>
                    <Col xs={4} sm={4} md={4} lg={4}>
                        {/* <a className="btn-warning" onClick={handleSearch}>Search</a> */}
                        <Button variant="outline-dark" className="btn-warning" onClick={handleSearch}>Search</Button>
                    </Col>
                </div>
                <hr />
                <Row className="mb-3">
                    <h4 style={{ backgroundColor: "whitesmoke" }}>Users</h4>
                    <Col xs={6} sm={6} md={4} lg={4}>
                        <Card className="mt-2">
                            <CardHeader>Beginning Users</CardHeader>
                            <CardBody>
                                {remainingDetails.currentMonthUsersCount}
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs={6} sm={6} md={4} lg={4}>
                        <Card className="mt-2">
                            <CardHeader>New Users</CardHeader>
                            <CardBody>
                                {remainingDetails.userCount}
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs={6} sm={6} md={4} lg={4}>
                        <Card className="mt-2">
                            <CardHeader>End Users</CardHeader>
                            <CardBody>
                                {remainingDetails.userCount + remainingDetails.currentMonthUsersCount}
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs={6} sm={6} md={4} lg={4}>
                        <Card className="mt-2">
                            <CardHeader>Super Active Users</CardHeader>
                            <CardBody>
                                {remainingDetails.superActiveUsers}
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs={6} sm={6} md={4} lg={4}>
                        <Card className="mt-2">
                            <CardHeader>Active Users</CardHeader>
                            <CardBody>
                                {remainingDetails.activeUsers}
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs={6} sm={6} md={4} lg={4}>
                        <Card className="mt-2">
                            <CardHeader>InActive Users</CardHeader>
                            <CardBody>
                                {remainingDetails.inActiveUsers}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <h4 style={{ backgroundColor: "whitesmoke" }}>House</h4>

                    <Col xs={6} sm={6} md={4} lg={4}>
                        <Card className="mt-2">
                            <CardHeader>House Profit</CardHeader>
                            <CardBody>
                                {typeof remainingDetails.houseProfit === 'number'
                                    ? remainingDetails.houseProfit.toFixed(2) + ' XAF'
                                    : !isNaN(parseFloat(remainingDetails.houseProfit))
                                        ? parseFloat(remainingDetails.houseProfit).toFixed(2) + ' XAF'
                                        : remainingDetails.houseProfit
                                }
                            </CardBody>
                        </Card>
                    </Col>

                    <Col xs={6} sm={6} md={4} lg={4}>
                        <Card className="mt-2">
                            <CardHeader>House Win</CardHeader>
                            <CardBody>
                                {typeof remainingDetails.houseWin === 'number'
                                    ? remainingDetails.houseWin.toFixed(2) + ' XAF'
                                    : !isNaN(parseFloat(remainingDetails.houseWin))
                                        ? parseFloat(remainingDetails.houseWin).toFixed(2) + ' XAF'
                                        : remainingDetails.houseWin
                                }
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs={6} sm={6} md={4} lg={4}>
                        <Card className="mt-2">
                            <CardHeader>House Lost</CardHeader>
                            <CardBody>
                                {typeof remainingDetails.houseLost === 'number'
                                    ? remainingDetails.houseLost.toFixed(2) + ' XAF'
                                    : !isNaN(parseFloat(remainingDetails.houseLost))
                                        ? parseFloat(remainingDetails.houseLost).toFixed(2) + ' XAF'
                                        : remainingDetails.houseLost
                                }
                            </CardBody>
                        </Card>
                    </Col>

                </Row>

                <Row className="mb-3">
                    <h4 style={{ backgroundColor: "whitesmoke" }}>Bets</h4>

                    <Col xs={6} sm={6} md={4} lg={4}>
                        <Card className="mt-2">
                            <CardHeader>Bets Amount</CardHeader>
                            <CardBody>
                                {typeof remainingDetails.totalBetsAmount === 'number'
                                    ? remainingDetails.totalBetsAmount.toFixed(2) + ' XAF'
                                    : !isNaN(parseFloat(remainingDetails.totalBetsAmount))
                                        ? parseFloat(remainingDetails.totalBetsAmount).toFixed(2) + ' XAF'
                                        : remainingDetails.totalBetsAmount
                                }
                            </CardBody>
                        </Card>
                    </Col>

                    <Col xs={6} sm={6} md={4} lg={4}>
                        <Card className="mt-2">
                            <CardHeader>Placed Bets</CardHeader>
                            <CardBody>
                                {remainingDetails.userBetsCount}
                            </CardBody>
                        </Card>
                    </Col>

                    <Col xs={6} sm={6} md={4} lg={4}>
                        <Card className="mt-2">
                            <CardHeader>Players Bets Count</CardHeader>
                            <CardBody>
                                {remainingDetails.numberOfUniquePlayersPlacedBets}
                            </CardBody>
                        </Card>
                    </Col>

                    <Col xs={6} sm={6} md={4} lg={4}>
                        <Card className="mt-2">
                            <CardHeader>Bets Amount Average</CardHeader>
                            <CardBody>
                                {remainingDetails.averageOfUserBetsAmount} XAF
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <h4 style={{ backgroundColor: "whitesmoke" }}>Rounds</h4>

                    <Col xs={6} sm={6} md={4} lg={4}>
                        <Card className="mt-2">
                            <CardHeader>Rounds</CardHeader>
                            <CardBody>
                                {remainingDetails.roundCount}
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs={6} sm={6} md={4} lg={4}>
                        <Card className="mt-2">
                            <CardHeader>More than 1 player Rounds</CardHeader>
                            <CardBody>
                                {remainingDetails.moreThanOnePlayersRoundCount}
                            </CardBody>
                        </Card>
                    </Col>

                    <Col xs={6} sm={6} md={4} lg={4}>
                        <Card className="mt-2">
                            <CardHeader>Rounds Players Count</CardHeader>
                            <CardBody>
                                {remainingDetails.numberOfRoundsUniquePlayersCount}
                            </CardBody>
                        </Card>
                    </Col>

                </Row>

                <Row className="mb-3">
                    <h4 style={{ backgroundColor: "whitesmoke" }}>Transactions</h4>

                    <Col xs={6} sm={6} md={4} lg={4}>
                        <Card className="mt-2">
                            <CardHeader>Deposit Transaction Count</CardHeader>
                            <CardBody>
                                {remainingDetails.depositTransactionCount}
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs={6} sm={6} md={4} lg={4}>
                        <Card className="mt-2">
                            <CardHeader>Withdraw Transaction Count</CardHeader>
                            <CardBody>
                                {remainingDetails.withdrawTransactionCount}
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs={6} sm={6} md={4} lg={4}>
                        <Card className="mt-2">
                            <CardHeader>Failed Deposit Transaction Count</CardHeader>
                            <CardBody>
                                {remainingDetails.depositFailedTransactionCount}
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs={6} sm={6} md={4} lg={4}>
                        <Card className="mt-2">
                            <CardHeader>Failed Withdraw Transaction Count</CardHeader>
                            <CardBody>
                                {remainingDetails.withdrawFailedTransactionCount}
                            </CardBody>
                        </Card>
                    </Col>

                    <Col xs={6} sm={6} md={4} lg={4}>
                        <Card className="mt-2">
                            <CardHeader> Deposit Transaction Sum</CardHeader>
                            <CardBody>
                                {remainingDetails.sumOfDepositTransactions}
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs={6} sm={6} md={4} lg={4}>
                        <Card className="mt-2">
                            <CardHeader> Withdraw Transaction Sum</CardHeader>
                            <CardBody>
                                {remainingDetails.sumOfWithdrawTransactions}
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs={6} sm={6} md={4} lg={4}>
                        <Card className="mt-2">
                            <CardHeader> Failed Deposit Transaction Sum</CardHeader>
                            <CardBody>
                                {remainingDetails.sumOfFailedDepositTransactions}
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs={6} sm={6} md={4} lg={4}>
                        <Card className="mt-2">
                            <CardHeader> Failed Withdraw Transaction Sum</CardHeader>
                            <CardBody>
                                {remainingDetails.sumOfFailedWithdrawTransactions}
                            </CardBody>
                        </Card>
                    </Col>

                </Row>

                <Row>

                    <h4 style={{ backgroundColor: "whitesmoke" }}>Others</h4>

                    <Col xs={6} sm={6} md={4} lg={4}>
                        <Card className="mt-2">
                            <CardHeader>Withdraw Commission</CardHeader>
                            <CardBody>
                                {
                                    typeof remainingDetails.withdrawalFee === 'number'
                                        ? remainingDetails.withdrawalFee.toFixed(2) + ' XAF'
                                        : !isNaN(parseFloat(remainingDetails.withdrawalFee))
                                            ? parseFloat(remainingDetails.withdrawalFee).toFixed(2) + ' XAF'
                                            : remainingDetails.withdrawalFee
                                }
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs={6} sm={6} md={4} lg={4}>
                        <Card className="mt-2">
                            <CardHeader>Tax Collected</CardHeader>
                            <CardBody>
                                {
                                    typeof remainingDetails.withdrawalTax === 'number'
                                        ? remainingDetails.withdrawalTax.toFixed(2) + ' XAF'
                                        : !isNaN(parseFloat(remainingDetails.withdrawalTax))
                                            ? parseFloat(remainingDetails.withdrawalTax).toFixed(2) + ' XAF'
                                            : remainingDetails.withdrawalTax
                                }
                            </CardBody>
                        </Card>
                    </Col>

                </Row >
                <Row>
                    <Col className="col-12 col-md-12">
                        <Card className="mt-2">
                            <CardHeader>Last 28 Days House Profit and Loss</CardHeader>
                            <CardBody>
                                <Line
                                    data={{
                                        labels: dailyHouseProfitChartLabels,
                                        datasets: [
                                            {
                                                label: "Profit",
                                                data: dailyHouseProfitChartValues,
                                                fill: true,
                                                borderWidth: 3,
                                                backgroundColor: "#ADDFAD",
                                                borderColor: '#228B22',
                                                pointRadius: 3,
                                                pointBackgroundColor: 'rgba(255, 255, 255, 1)',
                                                pointBorderColor: '#228B22',
                                                pointBorderWidth: 2,
                                                responsive: true
                                            },
                                            {
                                                label: "Loss",
                                                data: dailyHouseLossChartValues,
                                                fill: true,
                                                borderWidth: 3,
                                                backgroundColor: "#FAA0A0",
                                                borderColor: 'red',
                                                pointRadius: 3,
                                                pointBackgroundColor: 'rgba(255, 255, 255, 1)',
                                                pointBorderColor: 'red',
                                                pointBorderWidth: 2,
                                                responsive: true
                                            },
                                        ],
                                    }}
                                    options={{
                                        ...commonChartOptionsForAmount,
                                        elements: {
                                            line: {
                                                tension: 0.3,
                                            }
                                        }
                                    }}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                    <Col className="col-12 col-md-6">
                        <Card className="mt-2">
                            <CardHeader>Last 28 Days Placed Bets Count</CardHeader>
                            <CardBody>
                                <Bar
                                    data={{
                                        labels: dailyBetsChartLabels,
                                        datasets: [
                                            {
                                                label: "Bets",
                                                data: dailyBetsChartValues,
                                                fill: true,
                                                borderWidth: 3,
                                                backgroundColor: "rgba(54, 162, 235, 0.4)",
                                                borderColor: 'rgba(54, 162, 235, 1)',
                                                pointRadius: 3,
                                                pointBackgroundColor: 'rgba(255, 255, 255, 1)',
                                                pointBorderColor: 'rgba(54, 162, 235, 1)',
                                                pointBorderWidth: 2,
                                                responsive: true
                                            },
                                        ],
                                    }}
                                    options={{
                                        ...commonChartOptionsForCount,
                                        elements: {
                                            line: {
                                                tension: 0.3,
                                            }
                                        }
                                    }}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                    <Col className="col-12 col-md-6">
                        <Card className="mt-2">
                            <CardHeader>Last 28 Days Placed Bets Amount</CardHeader>
                            <CardBody>
                                <Line
                                    data={{
                                        labels: dailyBetsAmountChartLabels,
                                        datasets: [
                                            {
                                                label: "Amount",
                                                data: dailyBetsAmountChartValues,
                                                fill: true,
                                                borderWidth: 3,
                                                backgroundColor: "rgba(75, 192, 192, 0.4)", // Light green
                                                borderColor: 'rgba(75, 192, 192, 1)', // Dark green
                                                pointRadius: 3,
                                                pointBackgroundColor: 'rgba(255, 255, 255, 1)',
                                                pointBorderColor: 'rgba(75, 192, 192, 1)', // Dark green
                                                pointBorderWidth: 2,
                                                responsive: true
                                            },
                                        ],
                                    }}
                                    options={{
                                        ...commonChartOptionsForAmount,
                                        elements: {
                                            line: {
                                                tension: 0.3,
                                            }
                                        }
                                    }}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                    <Col className="col-12 col-md-6">
                        <Card className="mt-2">
                            <CardHeader>Last 28 Days User Registrations</CardHeader>
                            <CardBody>
                                <Bar
                                    data={{
                                        labels: dailyUsersChartLabels,
                                        datasets: [
                                            {
                                                label: "Users",
                                                data: dailyUsersChartValues,
                                                fill: false,
                                                borderWidth: 3,
                                                backgroundColor: "rgba(255, 99, 132, 0.4)",
                                                borderColor: 'rgba(255, 99, 132, 1)',
                                                pointRadius: 3,
                                                pointBackgroundColor: 'rgba(255, 255, 255, 1)',
                                                pointBorderColor: 'rgba(255, 99, 132, 1)',
                                                pointBorderWidth: 2,
                                                responsive: true
                                            },
                                        ],
                                    }}
                                    options={commonChartOptionsForCount}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container >

        </>
    );
};

export default Dashboard;