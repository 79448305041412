import React from "react";
import { Route, Routes } from "react-router-dom";
import Header from "./pages/header/Header";
import Login from "./pages/auth/login/Login"
import Dashboard from "./pages/components/dashboard/Dashboard";
import Transactions from "./pages/components/transaction/Transactions";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Users from "./pages/components/users/Users";
import FirebaseNotification from "./pages/components/firebasenotification/FirebaseNotification";
import Userbets from "./pages/components/userbets/Userbets";
import SendXAF from "./pages/components/sendXAF/SendXAF";
import ViewUser from "./pages/components/view-user/ViewUser";

function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/transactions" element={<Transactions />} />
        <Route path="/firebase" element={<FirebaseNotification />} />
        <Route path="/userbets" element={<Userbets />} />
        <Route path="/users" element={<Users />} />
        <Route path="/send-xaf" element={<SendXAF />} />
        <Route path="/user/:id/view" element={<ViewUser />} />
      </Routes>
      <ToastContainer />
    </>
  );
}

export default App;
