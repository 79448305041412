import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import "./FirebaseNotification.css";
import Loader from "../Loader/Loader";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { endpoint } from "../../../utils/endpoint";
import { fetchPost } from "../../../utils/network";

const FirebaseNotification = () => {
    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');
    const [attachment, setAttachment] = useState(null);
    const [showLoader, setshowLoader] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setAttachment(file);
    };

    const handlePushNotification = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            setshowLoader(true);
            const formData = new FormData();
            formData.append('title', title);
            formData.append('body', body);
            formData.append('attachment', attachment);
            formData.forEach((value, key) => {
                console.log(`${key}: ${value}`);
            });
            var data = { title: title, body: body }
            await fetchPost(endpoint["send-firebase-notification"], data, navigate);
            setshowLoader(false);
        } catch (error) {
            throw error;
        } finally {
            setLoading(false);
            setshowLoader(false);
        }
    };

    return (
        <>
            {showLoader && <Loader></Loader>}
            <div className="container firebase-form p-4">
                <h1 className="mb-5">Firebase Push Notification</h1>
                <div className="row">
                    <div className="col-md-6 mb-4">
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Title"
                            className="full-width"
                        >
                            <Form.Control
                                type="text"
                                placeholder="Title"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </FloatingLabel>
                    </div>
                    <div className="col-md-6 mb-4">
                        <FloatingLabel controlId="floatingBody" label="Body" className="full-width">
                            <Form.Control
                                type="text"
                                placeholder="Body"
                                value={body}
                                onChange={(e) => setBody(e.target.value)}
                            />
                        </FloatingLabel>
                    </div>
                    <div className="col-md-12 mb-4">
                        <Form.Group controlId="formFile">
                            <Form.Label>Attachment</Form.Label>
                            <Form.Control type="file" onChange={handleFileChange} />
                        </Form.Group>
                    </div>
                    <div className="col-md-12">
                        <Button variant="primary" size="lg"
                            className="full-width"
                            onClick={handlePushNotification}
                            disabled={loading}>
                            {loading ? 'Sending...' : 'Send'}
                        </Button>
                    </div>
                </div>
            </div>
        </>

    );
}

export default FirebaseNotification;
