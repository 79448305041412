import React, { useEffect, useState } from 'react';
import { Button, Tab, Tabs, Card, Container, Table, Modal, Row, Pagination, Col } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from "../Loader/Loader";
import { endpoint } from "../../../utils/endpoint";
import { fetchPost } from "../../../utils/network";

const ViewUser = () => {
    let { id } = useParams();
    const navigate = useNavigate();
    const [showLoader, setshowLoader] = useState(false);
    const [user, setUser] = useState('');
    const pageSize = 10;
    const pagesToShow = 5;
    //transactions
    const [showModal, setShowModal] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const [transactions, setTransactions] = useState([]);
    const [totalTransactionPages, setTotalTransactionPages] = useState(1);
    const [currentTransactionsPage, setCurrentTransactionsPage] = useState(1);
    //userbets
    const [userbets, setUserbets] = useState([]);
    const [totalUserbetPages, setTotalUserbetPages] = useState(1);
    const [currentUserbetsPage, setCurrentUserbetsPage] = useState(1);
    //transfers
    const [transfers, setTransfers] = useState([]);
    const [totalTransferPages, setTotalTransferPages] = useState(1);
    const [currentTransfersPage, setCurrentTransfersPage] = useState(1);
    //balance Logs
    const [balanceLogs, setBalanceLogs] = useState([]);
    const [totalBalanceLogPages, setTotalBalanceLogPages] = useState(1);
    const [currentBalanceLogsPage, setCurrentBalanceLogsPage] = useState(1);

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedTransaction(null);
    };

    const handleViewClick = (transaction) => {
        setSelectedTransaction(transaction);
        setShowModal(true);
    };

    useEffect(() => {
        const fetchUser = async () => {
            try {
                setshowLoader(true);
                let obj = {
                    userId: id,
                }
                const response = await fetchPost(endpoint["get-user-by-id"], obj, navigate);
                // console.log(response);
                setshowLoader(false);
                if (response.responseData && response.responseCode === 200) {
                    //user
                    setUser(response.responseData.user);
                }
            } catch (error) {
                throw error;
            } finally {
                setshowLoader(false);
            }
        };
        fetchUser();
    }, [id, navigate]);

    useEffect(() => {
        const handleGetTransactionByUserId = async () => {
            try {
                setshowLoader(true);
                let obj = {
                    userId: id,
                    pageNumber: currentTransactionsPage,
                    pageSize: pageSize,
                }
                const response = await fetchPost(endpoint["Get-transaction-by-user-id"], obj, navigate);
                // console.log(response);
                setshowLoader(false);
                if (response.responseData && response.responseCode === 200) {
                    //transactions
                    setTotalTransactionPages(response.responseData.totalTransactionsPages);
                    setTransactions(response.responseData.transactions);
                }
            } catch (error) {
                throw error;
            } finally {
                setshowLoader(false);
            }
        }
        handleGetTransactionByUserId()
    }, [id, currentTransactionsPage, navigate]);

    useEffect(() => {
        const handleGetUserbetsByUserId = async () => {
            try {
                setshowLoader(true);
                let obj = {
                    userId: id,
                    pageNumber: currentUserbetsPage,
                    pageSize: pageSize,
                }
                const response = await fetchPost(endpoint["Get-userbets-by-user-id"], obj, navigate);
                // console.log(response);
                setshowLoader(false);
                if (response.responseData && response.responseCode === 200) {
                    //userbets
                    setTotalUserbetPages(response.responseData.totalUserbetsPages);
                    setUserbets(response.responseData.userbets);
                }
            } catch (error) {
                throw error;
            } finally {
                setshowLoader(false);
            }
        }
        handleGetUserbetsByUserId()
    }, [id, currentUserbetsPage, navigate]);

    useEffect(() => {
        const handleGetTransfersByUserId = async () => {
            try {
                setshowLoader(true);
                let obj = {
                    userId: id,
                    pageNumber: currentTransfersPage,
                    pageSize: pageSize,
                }
                const response = await fetchPost(endpoint["Get-xaf-transfers-by-user-id"], obj, navigate);
                // console.log(response);
                setshowLoader(false);
                if (response.responseData && response.responseCode === 200) {
                    //transfers
                    setTotalTransferPages(response.responseData.totalTransfersPages);
                    setTransfers(response.responseData.transfers);
                }
            } catch (error) {
                throw error;
            } finally {
                setshowLoader(false);
            }
        }
        handleGetTransfersByUserId()
    }, [id, currentTransfersPage, navigate]);

    useEffect(() => {
        const handleGetBalanceLogsByUserId = async () => {
            try {
                setshowLoader(true);
                let obj = {
                    userId: id,
                    pageNumber: currentBalanceLogsPage,
                    pageSize: pageSize,
                }
                const response = await fetchPost(endpoint["Get-balance-logs-by-user-id"], obj, navigate);
                // console.log(response);
                setshowLoader(false);
                if (response.responseData && response.responseCode === 200) {
                    //balance logs
                    setTotalBalanceLogPages(response.responseData.totalBalanceLogsPages);
                    setBalanceLogs(response.responseData.balanceLogs);
                }
            } catch (error) {
                throw error;
            } finally {
                setshowLoader(false);
            }
        }
        handleGetBalanceLogsByUserId();
    }, [id, currentBalanceLogsPage, navigate]);

    function formatTimestamp(inputDate) {
        const date = new Date(inputDate);

        // Format date
        const formattedDate = `${date.getFullYear()}/${padNumber(date.getMonth() + 1)}/${padNumber(date.getDate())}`;

        // Format time
        const hours = padNumber(date.getHours());
        const minutes = padNumber(date.getMinutes());
        // const seconds = padNumber(date.getSeconds());
        const meridiem = hours >= 12 ? 'PM' : 'AM';
        const formattedTime = `${hours % 12 || 12}:${minutes} ${meridiem}`;

        // Combine date and time
        const formattedDateTime = `${formattedDate}, ${formattedTime}`;

        return formattedDateTime;
    }

    function padNumber(number) {
        return number < 10 ? `0${number}` : number;
    }

    // Pagination for Transactions
    const handleTransactionsPageChange = (page) => {
        setCurrentTransactionsPage(page);
    };

    const renderTransactionsPaginationItems = () => {
        const items = [];
        const startPage = Math.max(1, currentTransactionsPage - Math.floor(pagesToShow / 2));
        const endPage = Math.min(totalTransactionPages, startPage + pagesToShow - 1);

        items.push(
            <Pagination.First key="first" onClick={() => handleTransactionsPageChange(1)} disabled={currentTransactionsPage === 1} />
        );
        items.push(
            <Pagination.Prev
                key="prev"
                onClick={() => handleTransactionsPageChange(currentTransactionsPage - 1)}
                disabled={currentTransactionsPage === 1}
            />
        );

        for (let i = startPage; i <= endPage; i++) {
            items.push(
                <Pagination.Item
                    key={i}
                    active={i === currentTransactionsPage}
                    onClick={() => handleTransactionsPageChange(i)}
                >
                    {i}
                </Pagination.Item>
            );
        }

        items.push(
            <Pagination.Next
                key="next"
                onClick={() => handleTransactionsPageChange(currentTransactionsPage + 1)}
                disabled={currentTransactionsPage === totalTransactionPages}
            />
        );
        items.push(
            <Pagination.Last
                key="last"
                onClick={() => handleTransactionsPageChange(totalTransactionPages)}
                disabled={currentTransactionsPage === totalTransactionPages}
            />
        );

        return items;
    };

    // Pagination for Userbets
    const handleUserbetsPageChange = (page) => {
        setCurrentUserbetsPage(page);
    };

    const renderUserbetsPaginationItems = () => {
        const items = [];
        const startPage = Math.max(1, currentUserbetsPage - Math.floor(pagesToShow / 2));
        const endPage = Math.min(totalUserbetPages, startPage + pagesToShow - 1);

        items.push(
            <Pagination.First key="first" onClick={() => handleUserbetsPageChange(1)} disabled={currentUserbetsPage === 1} />
        );
        items.push(
            <Pagination.Prev
                key="prev"
                onClick={() => handleUserbetsPageChange(currentUserbetsPage - 1)}
                disabled={currentUserbetsPage === 1}
            />
        );

        for (let i = startPage; i <= endPage; i++) {
            items.push(
                <Pagination.Item
                    key={i}
                    active={i === currentUserbetsPage}
                    onClick={() => handleUserbetsPageChange(i)}
                >
                    {i}
                </Pagination.Item>
            );
        }

        items.push(
            <Pagination.Next
                key="next"
                onClick={() => handleUserbetsPageChange(currentUserbetsPage + 1)}
                disabled={currentUserbetsPage === totalUserbetPages}
            />
        );
        items.push(
            <Pagination.Last
                key="last"
                onClick={() => handleUserbetsPageChange(totalUserbetPages)}
                disabled={currentUserbetsPage === totalUserbetPages}
            />
        );

        return items;
    };

    // Pagination for Transfers
    const handleTransfersPageChange = (page) => {
        setCurrentTransfersPage(page);
    };

    const renderTransferPaginationItems = () => {
        const items = [];
        const startPage = Math.max(1, currentTransfersPage - Math.floor(pagesToShow / 2));
        const endPage = Math.min(totalTransferPages, startPage + pagesToShow - 1);

        items.push(
            <Pagination.First key="first" onClick={() => handleTransfersPageChange(1)} disabled={currentTransfersPage === 1} />
        );
        items.push(
            <Pagination.Prev
                key="prev"
                onClick={() => handleTransfersPageChange(currentTransfersPage - 1)}
                disabled={currentTransfersPage === 1}
            />
        );

        for (let i = startPage; i <= endPage; i++) {
            items.push(
                <Pagination.Item
                    key={i}
                    active={i === currentTransfersPage}
                    onClick={() => handleTransfersPageChange(i)}
                >
                    {i}
                </Pagination.Item>
            );
        }

        items.push(
            <Pagination.Next
                key="next"
                onClick={() => handleTransfersPageChange(currentTransfersPage + 1)}
                disabled={currentTransfersPage === totalTransferPages}
            />
        );
        items.push(
            <Pagination.Last
                key="last"
                onClick={() => handleTransfersPageChange(totalTransferPages)}
                disabled={currentTransfersPage === totalTransferPages}
            />
        );

        return items;
    };

    // Pagination for balance logs
    const handleBalanceLogsPageChange = (page) => {
        setCurrentBalanceLogsPage(page);
    };

    const renderBalanceLogsPaginationItems = () => {
        const items = [];
        const startPage = Math.max(1, currentBalanceLogsPage - Math.floor(pagesToShow / 2));
        const endPage = Math.min(totalBalanceLogPages, startPage + pagesToShow - 1);

        items.push(
            <Pagination.First key="first" onClick={() => handleBalanceLogsPageChange(1)} disabled={currentBalanceLogsPage === 1} />
        );
        items.push(
            <Pagination.Prev
                key="prev"
                onClick={() => handleBalanceLogsPageChange(currentBalanceLogsPage - 1)}
                disabled={currentBalanceLogsPage === 1}
            />
        );

        for (let i = startPage; i <= endPage; i++) {
            items.push(
                <Pagination.Item
                    key={i}
                    active={i === currentBalanceLogsPage}
                    onClick={() => handleBalanceLogsPageChange(i)}
                >
                    {i}
                </Pagination.Item>
            );
        }

        items.push(
            <Pagination.Next
                key="next"
                onClick={() => handleBalanceLogsPageChange(currentBalanceLogsPage + 1)}
                disabled={currentBalanceLogsPage === totalBalanceLogPages}
            />
        );
        items.push(
            <Pagination.Last
                key="last"
                onClick={() => handleBalanceLogsPageChange(totalBalanceLogPages)}
                disabled={currentBalanceLogsPage === totalBalanceLogPages}
            />
        );

        return items;
    };

    return (
        <>
            {showLoader && <Loader></Loader>}
            <Container>
                <h1 className="text-center mt-5">View User</h1>
                <Card className="col-12 col-md-12">
                    <Card.Header>
                        <Tabs defaultActiveKey="first" id="uncontrolled-tab-example">
                            <Tab eventKey="first" title={<span style={{ color: 'black' }}>Details</span>}>
                                <Card.Body>
                                    {user ? (
                                        <div className='mt-3'>
                                            <p>ID: <strong>{user._id}</strong></p>
                                            <p>Username: <strong>{user.userName ?? 'N/A'}</strong></p>
                                            <p>Phone Number: <strong>{user.phoneNumber ?? 'N/A'}</strong></p>
                                            <p>Balance: <strong>{user.balance}</strong></p>
                                            <p>Bet Amount: <strong>{user.bet_amount}</strong></p>
                                            <p>bonus: <strong>{user.bonus}</strong></p>
                                            <p>Joined at: <strong>{formatTimestamp(user?.created) ?? 'N/A'}</strong></p>
                                            <p>bonusAvailed: <strong>{user.bonusAvailed ? 'Yes' : 'No'}</strong></p>
                                            {user.otp &&
                                                <p>OTP: <strong>{user?.otp ?? 'N/A'}</strong></p>
                                            }
                                            {user.otpExpiry &&
                                                <p>OTP Expiry: <strong>{formatTimestamp(user.otpExpiry)}</strong></p>
                                            }
                                            <p>payout_multiplier: <strong>{user.payout_multiplier}</strong></p>
                                            <p>Profit: <strong>{user.profit}</strong></p>
                                            <p>Referral Bonus: <strong>{user.referralBonus}</strong></p>
                                            <p>Referral Number: <strong>{user.referralNo}</strong></p>
                                            <p>Total Deposit: <strong>{user.totalDeposit}</strong></p>
                                            <p>Total Profit: <strong>{user.totalProfit}</strong></p>
                                            <p>Total WithDraw: <strong>{user.totalWithDraw}</strong></p>
                                            <p>First Deposit Amount: <strong>{user.firstDepositAmount}</strong></p>
                                            <p>Winning Balance: <strong>{user.winningBalance}</strong></p>
                                            <p>Is Account Restricted By Admin: <strong>{user.isAccountRestrictedByAdmin ? 'Yes' : 'No'}</strong></p>
                                        </div>
                                    ) : (
                                        <p className='mt-3'>Loading user details...</p>
                                    )}
                                </Card.Body>
                            </Tab>
                            <Tab eventKey="second" title={<span style={{ color: 'black' }}>Referrals</span>}>
                                <Card.Body>
                                    {user.referral && user.referral.length > 0 ? (
                                        <div className='mt-3'>
                                            {user.referral.map((referral, index) => (
                                                <div key={index}>
                                                    <p>ID: <strong>{referral._id}</strong></p>
                                                    <p>Phone Number: <strong>{referral.phoneNumber}</strong></p>
                                                    <p>Username: <strong>{referral.userName}</strong></p>
                                                    <p>Joined At: <strong>{formatTimestamp(referral.createdAt)}</strong></p>
                                                    <hr />
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <p className='mt-3'>No referrals found for this user.</p>
                                    )}
                                </Card.Body>
                            </Tab>
                            <Tab eventKey="third" title={<span style={{ color: 'black' }}>Transactions</span>}>
                                <Card.Body>
                                    {transactions.length === 0 ? (
                                        <p className='mt-3'>No transactions found for this user.</p>
                                    ) : (
                                        <>
                                            <Table striped bordered hover responsive>
                                                <thead>
                                                    <tr>
                                                        <th>Username</th>
                                                        <th>Phone</th>
                                                        <th>Amount</th>
                                                        <th>Country</th>
                                                        <th>Timestamp</th>
                                                        <th>Payment Type</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {transactions.map((transaction) => (
                                                        <tr key={transaction._id}>
                                                            <td>{transaction.user?.userName ?? 'N/A'}</td>
                                                            <td>{transaction.user?.phoneNumber ?? 'N/A'}</td>
                                                            <td>{transaction.amount}</td>
                                                            <td>{transaction.country}</td>
                                                            <td>{formatTimestamp(transaction.customerTimestamp)}</td>
                                                            <td>{transaction.paymentType}</td>
                                                            <td>{transaction.status}</td>
                                                            <td><Button variant="outline-primary" onClick={() => handleViewClick(transaction)}>View</Button></td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                            <Modal show={showModal} onHide={handleCloseModal}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Transaction Details</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    {selectedTransaction && (
                                                        <div>
                                                            <p>Transaction ID: <strong>{selectedTransaction._id}</strong></p>
                                                            <p>Username: <strong>{selectedTransaction.user?.userName ?? 'N/A'}</strong></p>
                                                            <p>Phone: <strong>{selectedTransaction.user?.phoneNumber ?? 'N/A'}</strong></p>
                                                            <p>Amount: <strong>{selectedTransaction.amount}</strong></p>
                                                            <p>Country: <strong>{selectedTransaction.country}</strong></p>
                                                            <p>Currency: <strong>{selectedTransaction.currency}</strong></p>
                                                            <p>Timestamp: <strong>{formatTimestamp(selectedTransaction.customerTimestamp)}</strong></p>
                                                            <p>Correspondent: <strong>{selectedTransaction.correspondent}</strong></p>
                                                            <p>Payer Address: <strong>{selectedTransaction.payerAddress}</strong></p>
                                                            <p>Payer Type: <strong>{selectedTransaction.payerType}</strong></p>
                                                            <p>Payment Type: <strong>{selectedTransaction.paymentType}</strong></p>
                                                            <p>Statement Description: <strong>{selectedTransaction.statementDescription}</strong></p>
                                                            <p>Status: <strong>{selectedTransaction.status}</strong></p>
                                                        </div>
                                                    )}
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button variant="secondary" onClick={handleCloseModal}>
                                                        Close
                                                    </Button>
                                                </Modal.Footer>
                                            </Modal>
                                            <Row>
                                                <Col className="d-flex justify-content-end">
                                                    <Pagination>{renderTransactionsPaginationItems()}</Pagination>
                                                </Col>
                                            </Row>
                                        </>
                                    )}
                                </Card.Body>
                            </Tab>
                            <Tab eventKey="fourth" title={<span style={{ color: 'black' }}>Userbets</span>}>
                                <Card.Body>
                                    {userbets.length === 0 ? (
                                        <p className='mt-3'>No user bets found for this user.</p>
                                    ) : (
                                        <>
                                            <Table striped bordered hover responsive>
                                                <thead>
                                                    <tr>
                                                        <th>Username</th>
                                                        <th>Phone</th>
                                                        <th>Amount</th>
                                                        <th>Balance Before</th>
                                                        <th>Balance After </th>
                                                        <th>Balance Result</th>
                                                        <th>Profit</th>
                                                        <th>Result</th>
                                                        <th>Status</th>
                                                        <th>Bet Place Dat</th>
                                                        <th>Bet Start Time</th>
                                                        <th>Bet End Time</th>
                                                        <th>Bet Duration</th>
                                                        <th>Bet Type</th>
                                                        <th>Cashed Out</th>
                                                        <th>Cashout Event</th>
                                                        <th>Round Busted</th>
                                                        <th>User ID</th>
                                                        <th>Winning Balance After</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {userbets.map((userbet) => (
                                                        <tr key={userbet._id}>
                                                            <td>{userbet.username}</td>
                                                            <td>{userbet.phoneNo}</td>
                                                            <td>{userbet.amount}</td>
                                                            <td>
                                                                {typeof userbet.balanceBefore === 'number'
                                                                    ? userbet.balanceBefore.toFixed(2)  // Convert to fixed decimal if it's a number
                                                                    : !isNaN(parseFloat(userbet.balanceBefore))
                                                                        ? parseFloat(userbet.balanceBefore).toFixed(2)  // If it's a string representation of a number
                                                                        : userbet.balanceBefore  // Otherwise, display the original value
                                                                }
                                                            </td>
                                                            <td>
                                                                {typeof userbet.balanceAfter === 'number'
                                                                    ? userbet.balanceAfter.toFixed(2)  // Convert to fixed decimal if it's a number
                                                                    : !isNaN(parseFloat(userbet.balanceAfter))
                                                                        ? parseFloat(userbet.balanceAfter).toFixed(2)  // If it's a string representation of a number
                                                                        : userbet.balanceAfter  // Otherwise, display the original value
                                                                }
                                                            </td>
                                                            <td>{userbet.balanceResult}</td>
                                                            <td>
                                                                {typeof userbet.profit === 'number'
                                                                    ? userbet.profit.toFixed(2)  // Convert to fixed decimal if it's a number
                                                                    : !isNaN(parseFloat(userbet.profit))
                                                                        ? parseFloat(userbet.profit).toFixed(2)  // If it's a string representation of a number
                                                                        : userbet.profit  // Otherwise, display the original value
                                                                }
                                                            </td>
                                                            <td>{userbet.result}</td>
                                                            <td>{userbet.status}</td>
                                                            <td>{formatTimestamp(userbet.betplacedat)}</td>
                                                            <td>{formatTimestamp(userbet.betStartTime)}</td>
                                                            <td>{formatTimestamp(userbet.betEndTime)}</td>
                                                            <td>{userbet.betDuration}</td>
                                                            <td>{userbet.betType}</td>
                                                            <td>{userbet.cashedOut}</td>
                                                            <td>{userbet.cashoutEvent}</td>
                                                            <td>{userbet.roundBusted}</td>
                                                            <td>{userbet.userId}</td>
                                                            <td>{userbet.winningBalanceAfter}</td>
                                                        </tr>
                                                    ))
                                                    }
                                                </tbody>
                                            </Table>
                                            <Row>
                                                <Col className="d-flex justify-content-end">
                                                    <Pagination>
                                                        {renderUserbetsPaginationItems()}
                                                    </Pagination>
                                                </Col>
                                            </Row>
                                        </>
                                    )}
                                </Card.Body>
                            </Tab>
                            <Tab eventKey="fifth" title={<span style={{ color: 'black' }}>Transfers</span>}>
                                <Card.Body>
                                    {transfers.length === 0 ? (
                                        <p className='mt-3'>No transfers found for this user.</p>
                                    ) : (
                                        <>
                                            <Table striped bordered hover responsive>
                                                <thead>
                                                    <tr>
                                                        <th>Sender User ID</th>
                                                        <th>Sender Name</th>
                                                        <th>Sender Phone Number</th>
                                                        <th>Sender Previous Balance</th>
                                                        <th>Sender After Balance</th>
                                                        <th>Sender Previous Winning Balance</th>
                                                        <th>Sender After Winning Balance</th>
                                                        <th>Receiver User ID</th>
                                                        <th>Receiver Name</th>
                                                        <th>Receiver Phone Number</th>
                                                        <th>Receiver Previous Balance</th>
                                                        <th>Receiver After Balance</th>
                                                        <th>Amount</th>
                                                        <th>Fee</th>
                                                        <th>Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {transfers.map((transfer) => (
                                                        <tr key={transfer._id}>
                                                            <td>{transfer.senderUserId}</td>
                                                            <td>{transfer.senderName}</td>
                                                            <td>{transfer.senderPhoneNumber}</td>
                                                            <td>
                                                                {typeof transfer.senderPreviousBalance === 'number'
                                                                    ? transfer.senderPreviousBalance.toFixed(2)  // Convert to fixed decimal if it's a number
                                                                    : !isNaN(parseFloat(transfer.senderPreviousBalance))
                                                                        ? parseFloat(transfer.senderPreviousBalance).toFixed(2)  // If it's a string representation of a number
                                                                        : transfer.senderPreviousBalance  // Otherwise, display the original value
                                                                }
                                                            </td>
                                                            <td>
                                                                {typeof transfer.senderAfterBalance === 'number'
                                                                    ? transfer.senderAfterBalance.toFixed(2)  // Convert to fixed decimal if it's a number
                                                                    : !isNaN(parseFloat(transfer.senderAfterBalance))
                                                                        ? parseFloat(transfer.senderAfterBalance).toFixed(2)  // If it's a string representation of a number
                                                                        : transfer.senderAfterBalance  // Otherwise, display the original value
                                                                }
                                                            </td>
                                                            <td>
                                                                {typeof transfer.senderPreviousWinningBalance === 'number'
                                                                    ? transfer.senderPreviousWinningBalance.toFixed(2)  // Convert to fixed decimal if it's a number
                                                                    : !isNaN(parseFloat(transfer.senderPreviousWinningBalance))
                                                                        ? parseFloat(transfer.senderPreviousWinningBalance).toFixed(2)  // If it's a string representation of a number
                                                                        : transfer.senderPreviousWinningBalance  // Otherwise, display the original value
                                                                }
                                                            </td>
                                                            <td>
                                                                {typeof transfer.senderAfterWinningBalance === 'number'
                                                                    ? transfer.senderAfterWinningBalance.toFixed(2)  // Convert to fixed decimal if it's a number
                                                                    : !isNaN(parseFloat(transfer.senderAfterWinningBalance))
                                                                        ? parseFloat(transfer.senderAfterWinningBalance).toFixed(2)  // If it's a string representation of a number
                                                                        : transfer.senderAfterWinningBalance  // Otherwise, display the original value
                                                                }
                                                            </td>
                                                            <td>{transfer.receiverUserId}</td>
                                                            <td>{transfer.receiverName}</td>
                                                            <td>{transfer.receiverPhoneNumber}</td>
                                                            <td>
                                                                {typeof transfer.receiverPreviousBalance === 'number'
                                                                    ? transfer.receiverPreviousBalance.toFixed(2)  // Convert to fixed decimal if it's a number
                                                                    : !isNaN(parseFloat(transfer.receiverPreviousBalance))
                                                                        ? parseFloat(transfer.receiverPreviousBalance).toFixed(2)  // If it's a string representation of a number
                                                                        : transfer.receiverPreviousBalance  // Otherwise, display the original value
                                                                }
                                                            </td>
                                                            <td>
                                                                {typeof transfer.receiverAfterBalance === 'number'
                                                                    ? transfer.receiverAfterBalance.toFixed(2)  // Convert to fixed decimal if it's a number
                                                                    : !isNaN(parseFloat(transfer.receiverAfterBalance))
                                                                        ? parseFloat(transfer.receiverAfterBalance).toFixed(2)  // If it's a string representation of a number
                                                                        : transfer.receiverAfterBalance  // Otherwise, display the original value
                                                                }
                                                            </td>
                                                            <td>{transfer.amount}</td>
                                                            <td>{transfer.fee}</td>
                                                            <td>{formatTimestamp(transfer.date)}</td>
                                                        </tr>
                                                    ))
                                                    }
                                                </tbody>
                                            </Table>
                                            <Row>
                                                <Col className="d-flex justify-content-end">
                                                    <Pagination>
                                                        {renderTransferPaginationItems()}
                                                    </Pagination>
                                                </Col>
                                            </Row>
                                        </>
                                    )}
                                </Card.Body>
                            </Tab>
                            <Tab eventKey="sixth" title={<span style={{ color: 'black' }}>Balance Logs</span>}>
                                <Card.Body>
                                    {balanceLogs.length === 0 ? (
                                        <p className='mt-3'>No balance logs found for this user.</p>
                                    ) : (
                                        <>
                                            <Table striped bordered hover responsive>
                                                <thead>
                                                    <tr>
                                                        <th>Amount</th>
                                                        <th>Balance</th>
                                                        <th>Winning Balance</th>
                                                        <th>Bonus</th>
                                                        <th>Description</th>
                                                        <th>Status</th>
                                                        <th>Transaction ID</th>
                                                        <th>Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {balanceLogs.map((log) => (
                                                        <tr key={log._id}>
                                                            <td>
                                                                {log.amount !== undefined && log.amount !== null
                                                                    ? typeof log.amount === 'number'
                                                                        ? log.amount.toFixed(2)  // Convert to fixed decimal if it's a number
                                                                        : !isNaN(parseFloat(log.amount))
                                                                            ? parseFloat(log.amount).toFixed(2)  // If it's a string representation of a number
                                                                            : log.amount  // Otherwise, display the original value
                                                                    : "N/A"  // Display null if log.amount doesn't exist
                                                                }
                                                            </td>
                                                            <td>
                                                                {typeof log.balance === 'number'
                                                                    ? log.balance.toFixed(2)  // Convert to fixed decimal if it's a number
                                                                    : !isNaN(parseFloat(log.balance))
                                                                        ? parseFloat(log.balance).toFixed(2)  // If it's a string representation of a number
                                                                        : log.balance  // Otherwise, display the original value
                                                                }
                                                            </td>
                                                            <td>
                                                                {typeof log.winningBalance === 'number'
                                                                    ? log.winningBalance.toFixed(2)  // Convert to fixed decimal if it's a number
                                                                    : !isNaN(parseFloat(log.winningBalance))
                                                                        ? parseFloat(log.winningBalance).toFixed(2)  // If it's a string representation of a number
                                                                        : log.winningBalance  // Otherwise, display the original value
                                                                }
                                                            </td>
                                                            <td>
                                                                {typeof log.bonus === 'number'
                                                                    ? log.bonus.toFixed(2)  // Convert to fixed decimal if it's a number
                                                                    : !isNaN(parseFloat(log.bonus))
                                                                        ? parseFloat(log.bonus).toFixed(2)  // If it's a string representation of a number
                                                                        : log.bonus  // Otherwise, display the original value
                                                                }
                                                            </td>
                                                            <td>{log.description}</td>
                                                            <td>{log.status}</td>
                                                            <td>
                                                                {log.transactionId !== undefined && log.transactionId !== null
                                                                    ? log.transactionId : "N/A"}
                                                            </td>
                                                            <td>{formatTimestamp(log.date)}</td>
                                                        </tr>
                                                    ))
                                                    }
                                                </tbody>
                                            </Table>
                                            <Row>
                                                <Col className="d-flex justify-content-end">
                                                    <Pagination>
                                                        {renderBalanceLogsPaginationItems()}
                                                    </Pagination>
                                                </Col>
                                            </Row>
                                        </>
                                    )}
                                </Card.Body>
                            </Tab>
                        </Tabs>
                    </Card.Header>
                </Card>
            </Container>

        </>
    );
};

export default ViewUser;