import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import "./Login.css";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import logo from "../../../assests/logo.png";
import Loader from "../../components/Loader/Loader";
import { endpoint } from "../../../utils/endpoint";
import { fetchPost } from "../../../utils/network";
import { toast } from 'react-toastify';


const Login = () => {
    const [formData, setFormData] = useState({
        email: "",
        password: "",
    });
    const [showLoader, setshowLoader] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            setshowLoader(true);
            const response = await fetchPost(endpoint.login, formData, navigate);
            setshowLoader(false);
            if (response.responseCode === 200) {
                // Save token to local storage
                localStorage.setItem("token", response.responseData.accessToken);
                navigate("/dashboard");
            } else if (response.responseCode === 6004) {
                toast.error('Invalid credentials', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
            }
        } catch (error) {
            throw error;
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            {showLoader && <Loader></Loader>}
            <div className="center-form">
                <img src={logo} alt="Logo" className="logo" />
                <h2>Enter Your Credentials</h2>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Control
                            type="email"
                            name="email"
                            placeholder="Enter email"
                            value={formData.email}
                            onChange={handleInputChange}
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicPassword">
                        <Form.Control
                            type="password"
                            name="password"
                            placeholder="Enter password"
                            value={formData.password}
                            onChange={handleInputChange}
                        />
                    </Form.Group>

                    <Button variant="Dark" type="submit" className="w-100" disabled={loading}>
                        {loading ? 'Logging in...' : 'Login'}
                    </Button>
                </Form>
            </div>
        </>
    );
};

export default Login;
