const endpoint = {
    'login': '/admin/login',
    'search-userbets': '/admin/search/user_bets',
    'get-all-userbets': '/admin/user_bets',
    'get-all-transactions': `/admin/transactions`,
    'get-pending-transactions': `/admin/pending_transactions`,
    'get-approved-transactions': `/admin/approved_transactions`,
    'approve-pending-transaction': `/admin/pending_transaction/approve`,
    'send-firebase-notification': `/admin/send-notification`,
    'get-analytics-chart-details': `/admin/analytics_chart_details`,
    'get-analytics-details': `/admin/analytics_details`,
    // 'seven-days-analytics-details': `/admin/analytics_details/seven_days`,
    // 'thirty-days-analytics-details': `/admin/analytics_details/thirty_days`,
    'get-all-users-details': `/admin/users`,
    'search-user-by-phone-number': `/admin/search-user-by-phone-number`,
    'change-user-password': `/admin/change-password`,
    'send-XAF': `/admin/send-xaf`,
    'get-send-xaf-logs-by-admin': `/admin/sent-xaf-logs`,
    'get-user-by-id': `/admin/get-user-by-id`,
    'Get-transaction-by-user-id': `/admin/get-transactions-by-userId`,
    'Get-userbets-by-user-id': `/admin/get-userbets-by-userId`,
    'Get-xaf-transfers-by-user-id': `/admin/get-transfers-by-userId`,
    'Get-balance-logs-by-user-id': `/admin/get-balance-logs-by-userId`,
    'change-balance-of-the-user': `/admin/change-balance`,
    'block-user': `/admin/block-user`,
    'unblock-user': `/admin/unblock-user`,
    'decline-pending-transaction': `/admin/pending_transactions/decline`,
}

export {
    endpoint
}