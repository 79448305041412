import React, { useState, useEffect } from 'react';
import Loader from '../Loader/Loader';
import { Button, Form, FloatingLabel, Modal, Pagination, Col, Row, Table, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { endpoint } from '../../../utils/endpoint';
import { fetchPost } from '../../../utils/network';
import { toast } from 'react-toastify';
import "./SendXAF.css";

const SendXAF = () => {
    const navigate = useNavigate();
    const [showLoader, setShowLoader] = useState(false);
    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');
    const [amount, setAmount] = useState('');
    const [showSendXAFModal, setShowSendXAFModal] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const [XAFLogs, setXAFLogs] = useState([]);
    const [totalXAFLogsPages, setTotalXAFLogPages] = useState(1);
    const [currentXAFLogPage, setCurrentXAFLogPage] = useState(1);
    const pageSize = 10;
    const pagesToShow = 5;

    const handleOpenSendXAFModal = async () => {
        setShowSendXAFModal(true);
    }

    const handleConfirmation = async () => {
        setShowSendXAFModal(false);
        try {
            setShowLoader(true);
            const obj = {
                title: title,
                body: body,
                amount: amount
            };
            const response = await fetchPost(endpoint["send-XAF"], obj, navigate);
            setShowLoader(false);
            if (response.responseCode === 200) {
                toast.success("XAF sent successfully");
                setAmount('');
            } else {
                toast.error("Something went wrong");
            }
        } catch (error) {
            throw error;
        } finally {
            setShowLoader(false);
        }
    };

    useEffect(() => {
        const fetchAllAdminSendXAFLogs = async () => {
            try {
                setShowLoader(true);
                let obj = {
                    pageNumber: currentXAFLogPage,
                    pageSize: pageSize,
                }
                const response = await fetchPost(endpoint["get-send-xaf-logs-by-admin"], obj, navigate);
                setShowLoader(false);
                if (response.responseData && response.responseData.adminXAFLogs) {
                    setXAFLogs(response.responseData.adminXAFLogs);
                    setTotalXAFLogPages(response.responseData.totalPages);
                }
            } catch (error) {
                throw error;
            } finally {
                setShowLoader(false);
            }
        };
        fetchAllAdminSendXAFLogs();
    }, [currentXAFLogPage, navigate]);

    const handleXAFLogPageChange = (page) => {
        setCurrentXAFLogPage(page);
    };

    const renderXAFLogsPaginationItems = () => {
        const items = [];
        const startPage = Math.max(1, currentXAFLogPage - Math.floor(pagesToShow / 2));
        const endPage = Math.min(totalXAFLogsPages, startPage + pagesToShow - 1);

        items.push(
            <Pagination.First key="first" onClick={() => handleXAFLogPageChange(1)} disabled={currentXAFLogPage === 1} />
        );
        items.push(
            <Pagination.Prev
                key="prev"
                onClick={() => handleXAFLogPageChange(currentXAFLogPage - 1)}
                disabled={currentXAFLogPage === 1}
            />
        );

        for (let i = startPage; i <= endPage; i++) {
            items.push(
                <Pagination.Item
                    key={i}
                    active={i === currentXAFLogPage}
                    onClick={() => handleXAFLogPageChange(i)}
                >
                    {i}
                </Pagination.Item>
            );
        }

        items.push(
            <Pagination.Next
                key="next"
                onClick={() => handleXAFLogPageChange(currentXAFLogPage + 1)}
                disabled={currentXAFLogPage === totalXAFLogsPages}
            />
        );
        items.push(
            <Pagination.Last
                key="last"
                onClick={() => handleXAFLogPageChange(totalXAFLogsPages)}
                disabled={currentXAFLogPage === totalXAFLogsPages}
            />
        );

        return items;
    };

    function formatTimestamp(inputDate) {
        const date = new Date(inputDate);

        // Format date
        const formattedDate = `${date.getFullYear()}/${padNumber(date.getMonth() + 1)}/${padNumber(date.getDate())}`;

        // Format time
        const hours = padNumber(date.getHours());
        const minutes = padNumber(date.getMinutes());
        // const seconds = padNumber(date.getSeconds());
        const meridiem = hours >= 12 ? 'PM' : 'AM';
        const formattedTime = `${hours % 12 || 12}:${minutes} ${meridiem}`;

        // Combine date and time
        const formattedDateTime = `${formattedDate}, ${formattedTime}`;

        return formattedDateTime;
    }

    function padNumber(number) {
        return number < 10 ? `0${number}` : number;
    }

    return (
        <>
            {showLoader && <Loader />}
            <Button variant="outline-primary" onClick={handleOpenSendXAFModal} className="sendXAFButton">
                Send XAF
            </Button>

            <div>
                <Container className="mt-3">
                    <Row>
                        <Col>
                            <h1 className="text-center">XAF Logs</h1>
                            <Table striped bordered hover responsive>
                                <thead>
                                    <tr>
                                        <th>Title</th>
                                        <th>Body</th>
                                        <th>Amount</th>
                                        <th>Users Count</th>
                                        <th>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {XAFLogs.map((XAFLog) => (
                                        <tr key={XAFLog._id}>
                                            <td>{XAFLog.title}</td>
                                            <td>{XAFLog.body}</td>
                                            <td>{XAFLog.amount}</td>
                                            <td>{XAFLog.usersCount}</td>
                                            <td>{formatTimestamp(XAFLog.date)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            <Row>
                                <Col className="d-flex justify-content-end">
                                    <Pagination>
                                        {renderXAFLogsPaginationItems()}
                                    </Pagination>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container >
            </div >

            <Modal show={showSendXAFModal} onHide={() => setShowSendXAFModal(false)}>
                <Modal.Body>
                    {currentStep === 1 ? (
                        <div>
                            <h1 className="mb-3 text-center">Send XAF</h1>
                            <div className="row">
                                <div className="col-md-12 mb-4">
                                    <FloatingLabel controlId="floatingInput" label="Title" className="full-width">
                                        <Form.Control
                                            type="text"
                                            placeholder="Title"
                                            value={title}
                                            onChange={(e) => setTitle(e.target.value)}
                                        />
                                    </FloatingLabel>
                                </div>
                                <div className="col-md-12 mb-4">
                                    <FloatingLabel controlId="floatingBody" label="Body" className="full-width">
                                        <Form.Control
                                            type="text"
                                            placeholder="Body"
                                            value={body}
                                            onChange={(e) => setBody(e.target.value)}
                                        />
                                    </FloatingLabel>
                                </div>
                                <div className="col-md-12 mb-4">
                                    <FloatingLabel controlId="floatingBody" label="Enter Amount" className="full-width">
                                        <Form.Control
                                            type="number"
                                            placeholder="Enter Amount"
                                            value={amount}
                                            onChange={(e) => setAmount(e.target.value)}
                                            className='amount-input'
                                        />
                                    </FloatingLabel>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <strong>Are you sure you want to send XAF?</strong>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    {currentStep === 1 ? (
                        <>
                            <Button variant="secondary" onClick={() => setShowSendXAFModal(false)}>
                                Cancel
                            </Button>
                            <Button variant="primary" onClick={() => setCurrentStep(2)} disabled={!amount || !title || !body}>
                                Next
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button variant="secondary" onClick={() => setCurrentStep(1)}>
                                Back
                            </Button>
                            <Button variant="primary" onClick={handleConfirmation} disabled={!amount || !title || !body}>
                                Send
                            </Button>
                        </>
                    )}
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default SendXAF;
